.header-section {
                  position: relative;
                  top: 0;
                  left: 0;
                  width: 100%;
                  z-index: 999;
                  background-color: white;
                }
                @media only screen and (max-width: 991px) {
                  .header-section .header-top-area .container {
                    max-width: 100%;
                  }
                }
                
                .header {
                  background-color: transparent;
                  width: 100%;
                  z-index: 99;
                  -webkit-transition: all ease 1s;
                  transition: all ease 1s;
                }
                .header.active {
                  transform: translateY(0%);
                  position: fixed;
                  top: 0;
                  left: 0;
                  animation-name: fadeInDown;
                  animation-duration: 1s;
                  transition: all 1s;
                }
                
                .header-section.header-fixed {
                  position: fixed;
                  top: 0;
                  left: 0;
                  width: 100%;
                  z-index: 9999;
                  background-color: white;
                }
                .header-section.header-fixed .header-top-area {
                  display: none;
                }
                .header-section.header-fixed .header-bottom-area {
                  background-color: transparent;
                }
                
                .header-bottom-area {
                  position: relative;
                  border-bottom: 1px solid #e1e7ec;
                  padding: 0 100px;
                }
                @media only screen and (max-width: 1400px) {
                  .header-bottom-area {
                    padding: 0 30px;
                  }
                }
                @media only screen and (max-width: 991px) {
                  .header-bottom-area {
                    padding: 5px 20px;
                  }
                }
                @media only screen and (max-width: 575px) {
                  .header-bottom-area {
                    padding: 5px 0;
                  }
                }
                .header-bottom-area .navbar-expand-lg {
                  background-color: transparent;
                  padding: 0 !important;
                }
                @media only screen and (max-width: 991px) {
                  .header-bottom-area .navbar-expand-lg {
                    padding: 0 !important;
                  }
                }
                @media (max-width: 767px) {
                  .header-bottom-area .navbar-collapse {
                    max-height: 320px;
                    overflow: auto;
                  }
                }
                @media only screen and (max-width: 991px) {
                  .header-bottom-area .navbar-collapse {
                    background-color: #f9f9f9;
                  }
                }
                @media (max-width: 991px) {
                  .header-bottom-area .navbar-collapse .main-menu {
                    padding: 15px 0;
                    background-color: #f9f9f9;
                  }
                  .header-bottom-area .menu_has_children .sub-menu {
                    display: none !important;
                  }
                  .header-bottom-area .menu_has_children .sub-menu li {
                    width: 100%;
                  }
                  .header-bottom-area .navbar-collapse .main-menu .menu_has_children.show .sub-menu,
                  .header-bottom-area .navbar-collapse .main-menu .menu_has_children.show .sub-menu {
                    display: flex !important;
                    flex-wrap: wrap;
                  }
                }
                .header-bottom-area .navbar-collapse .main-menu li:last-child .sub-menu {
                  left: auto;
                  right: 0;
                }
                .header-bottom-area .navbar-collapse .main-menu li {
                  position: relative;
                  padding-right: 30px;
                }
                @media only screen and (max-width: 1199px) {
                  .header-bottom-area .navbar-collapse .main-menu li {
                    padding-right: 18px;
                  }
                }
                .header-bottom-area .navbar-collapse .main-menu li:last-child {
                  padding-right: 0;
                  margin-right: 0;
                }
                .header-bottom-area .navbar-collapse .main-menu li:hover .sub-menu {
                  opacity: 1;
                  visibility: visible;
                  top: 100%;
                }
                .header-bottom-area .navbar-collapse .main-menu li.menu_has_children {
                  position: relative;
                }
                .header-bottom-area .navbar-collapse .main-menu li.menu_has_children i {
                  font-size: 12px;
                  margin-left: 5px;
                }
                @media (max-width: 991px) {
                  .header-bottom-area .navbar-collapse .main-menu li.menu_has_children::before {
                    top: 12px;
                    right: 15px;
                  }
                }
                .header-bottom-area .navbar-collapse .main-menu li a {
                  position: relative;
                  padding: 30px 0 30px 0;
                  font-weight: 500;
                  align-items: center;
                  display: inline-block;
                  font-size: 14px;
                  text-transform: uppercase;
                  font-family: "Maven Pro", sans-serif;
                  transition: all 0.9s;
                }
                .header-bottom-area .navbar-collapse .main-menu li a::after {
                  position: absolute;
                  content: '';
                  bottom: 0;
                  left: 0;
                  width: 0;
                  height: 2px;
                  background-color: #2cdd9b;
                  transition: all 0.9s;
                }
                .header-bottom-area .navbar-collapse .main-menu li a:hover, .header-bottom-area .navbar-collapse .main-menu li a.active {
                  color: #2cdd9b;
                }
                .header-bottom-area .navbar-collapse .main-menu li a:hover::after, .header-bottom-area .navbar-collapse .main-menu li a.active::after {
                  width: 100%;
                }
                .header-bottom-area .header-section.header-fixed .navbar-collapse .main-menu li a {
                  padding: 30px 18px 30px 0;
                }
                @media (max-width: 991px) {
                  .header-bottom-area .navbar-collapse .main-menu li a {
                    padding: 12px 15px !important;
                    display: block;
                  }
                }
                .header-bottom-area .navbar-collapse .main-menu li .sub-menu {
                  position: absolute;
                  top: 105%;
                  left: 0;
                  width: 170px;
                  background-color: #1e2746;
                  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
                  border-top: 2px solid #2cdd9b;
                  opacity: 0;
                  visibility: hidden;
                  -webkit-transition: all 0.3s;
                  transition: all 0.3s;
                  z-index: -9;
                  margin-left: 0;
                }
                .header-bottom-area .navbar-collapse .main-menu li .sub-menu li {
                  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
                  margin-right: 0;
                  transition: all ease 0.5s;
                  position: relative;
                  z-index: 2;
                }
                .header-bottom-area .navbar-collapse .main-menu li .sub-menu li:last-child {
                  border-bottom: 0px solid;
                }
                .header-bottom-area .navbar-collapse .main-menu li .sub-menu li:hover a {
                  color: white;
                }
                .header-bottom-area .navbar-collapse .main-menu li .sub-menu li a {
                  color: white;
                  width: 100%;
                  padding: 5px 20px;
                  font-size: 12px;
                  display: block;
                  transition: all ease 0.3s;
                }
                .header-bottom-area .navbar-collapse .main-menu li .sub-menu li a::after {
                  position: unset;
                  content: none;
                }
                .header-bottom-area .navbar-collapse .main-menu li .sub-menu .header-section.header-fixed .navbar-collapse .main-menu li .sub-menu li a {
                  padding: 8px 20px;
                }
                @media (max-width: 991px) {
                  .header-bottom-area .navbar-collapse .main-menu li .sub-menu .navbar-collapse .main-menu li .sub-menu {
                    position: initial;
                    opacity: 1;
                    visibility: visible;
                    display: none;
                    -webkit-transition: none;
                    transition: none;
                  }
                }
                .header-bottom-area .navbar-collapse .main-menu li .sub-menu .navbar-collapse .main-menu li:last-child .sub-menu {
                  left: auto;
                  right: 0;
                }
                
                .language-select-area {
                  position: relative;
                  z-index: 99;
                }
                @media only screen and (max-width: 991px) {
                  .language-select-area {
                    margin-left: 15px;
                    margin-bottom: 15px;
                  }
                }
                
                .language-select {
                  font-size: 14px;
                  border: 1px solid #e1e7ec;
                  font-weight: 500;
                  border-radius: 3px;
                  padding: 10px 10px;
                  background-color: transparent;
                }
                
                .site-logo {
                  max-width: 190px;
                }
                @media only screen and (max-width: 420px) {
                  .site-logo img {
                    height: 30px;
                  }
                }
                
                .header-social li {
                  display: inline-block;
                }
                .header-social li a {
                  color: white;
                  font-size: 12px;
                }
                @media only screen and (max-width: 991px) {
                  .header-social li a {
                    font-size: 12px;
                  }
                }
                .header-social li + li {
                  margin-left: 10px;
                }
                
                .header-action {
                  display: flex;
                  flex-wrap: wrap;
                  margin: -5px;
                  margin-left: 5px;
                }
                @media only screen and (max-width: 991px) {
                  .header-action {
                    margin: 0;
                    margin-left: 12px;
                    margin-bottom: 20px;
                    display: block;
                  }
                }
                .header-action a {
                  font-size: 14px;
                  padding: 8px 20px;
                  display: flex;
                  align-items: center;
                  margin: 5px;
                }
                .header-action a i {
                  font-size: 16px;
                  margin-right: 3px;
                }
                @media only screen and (max-width: 991px) {
                  .header-action a {
                    display: inline-block;
                  }
                }
                
                .header-short-menu {
                  padding: 10px 100px;
                  border-bottom: 1px solid #e1e7ec;
                }
                @media only screen and (max-width: 1400px) {
                  .header-short-menu {
                    padding: 0px 30px;
                  }
                }
                @media only screen and (max-width: 991px) {
                  .header-short-menu {
                    padding: 0;
                    border: none;
                  }
                }
                
                .short-menu {
                  display: -ms-flexbox;
                  display: flex;
                  -ms-flex-wrap: wrap;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  -webkit-transition: all 0.3s;
                  -o-transition: all 0.3s;
                  transition: all 0.3s;
                }
                .short-menu.open {
                  left: 0;
                }
                @media only screen and (max-width: 991px) {
                  .short-menu {
                    padding-top: 20px;
                    position: fixed;
                    display: block;
                    top: 0;
                    left: -100%;
                    background-color: white;
                    width: 220px;
                    z-index: 99;
                    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
                  }
                }
                .short-menu li {
                  font-size: 14px;
                  font-weight: 500;
                  -webkit-transition: all 0.3s;
                  -o-transition: all 0.3s;
                  transition: all 0.3s;
                }
                @media only screen and (max-width: 991px) {
                  .short-menu li {
                    padding: 6px 10px;
                    border-bottom: 1px solid #e1e7ec;
                  }
                  .short-menu li:first-child, .short-menu li:last-child {
                    border: none;
                  }
                }
                .short-menu li:hover {
                  color: #2cdd9b;
                }
                
                .short-menu-close-btn-area {
                  display: none;
                }
                @media only screen and (max-width: 991px) {
                  .short-menu-close-btn-area {
                    display: inline-block;
                  }
                }
                
                .short-menu-close-btn {
                  position: absolute;
                  top: 0px;
                  right: 0;
                  z-index: 91;
                  background-color: #2cdd9b;
                  font-weight: 500;
                  color: white;
                  padding: 3px 15px;
                  display: none;
                }
                @media only screen and (max-width: 991px) {
                  .short-menu-close-btn {
                    display: block;
                  }
                }
                
                .navbar-toggler:focus {
                  box-shadow: none;
                }
                
                .navbar-toggler span {
                  width: 40px;
                  height: 40px;
                  line-height: 40px;
                  border-radius: 5px;
                  color: #2cdd9b;
                  font-size: 30px;
                }
                @media only screen and (max-width: 991px) {
                  .navbar-toggler span {
                    font-size: 22px;
                  }
                }
                
                .short-menu-open-btn {
                  background-color: #2cdd9b;
                  color: white;
                  padding: 3px 15px;
                  border-radius: 3px;
                  display: none;
                }
                @media only screen and (max-width: 991px) {
                  .short-menu-open-btn {
                    display: block;
                  }
                }

.navbar-nav li a{
                  text-decoration: none;
                  color: #606975;
}
.short-menu li a{
                  text-decoration: none;
                  color: #606975;
                  font-size: 17px;
                  font-weight: 600;
}

.header-action a{
                  text-decoration: none;
}
.header-short-menu ul{
  margin-bottom: 10px;
}