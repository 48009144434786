.UserLogin{
                  background-color: #FFFFFF;
}
.UserSignUp{
                  background-color: #FFFFFF;
}
.ForgetPassword{
                  background-color: #FFFFFF;
}
.otp{
                  background-color: #FFFFFF;

}
.confirm-password{
                  background-color: #FFFFFF;
}
.ana7r-logo img{
                  width: 6rem;
}
.ana7r-card-title {
                  font-size: 1.2rem;
                  font-weight: 500;
}
.btn-login{
                  background-color: #002F34;
                  color: white;
                  font-size: 1.1rem;
    
}
.btn-login:hover{
                  background-color: #002F34;
                  color: white;
                  font-size: 1.1rem;
                  border: 1px solid white;
    
}
.term-and-condition a{
                  text-decoration: none;
}