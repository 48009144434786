a{
                  text-decoration: none;
}
.footer {
                  background-color: white;
                  color: rgb(2, 2, 2);
                  box-shadow: 0px 3px 6px rgba(71, 72, 57, 3.75);
              }
              
              .footer .btn.btn-social {
                  margin-right: 5px;
                  width: 35px;
                  height: 35px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: black;
                  border: 1px solid rgba(255, 255, 255, 0.5);
                  border-radius: 35px;
                  transition: .3s;
              }
              
              .footer .btn.btn-social:hover {
                  color: var(--primary);
                  border-color: black;
              }
              
              .footer .btn.btn-link {
                  display: block;
                  margin-bottom: 5px;
                  padding: 0;
                  text-align: left;
                  font-size: 15px;
                  font-weight: normal;
                  text-transform: capitalize;
                  transition: .3s;
                  color: rgb(0, 0, 0);
                  text-decoration: none;
              }
              
              .footer .btn.btn-link::before {
                  position: relative;
                  content: "\f105";
                  font-family: "Font Awesome 5 Free";
                  font-weight: 900;
                  margin-right: 10px;
              }
              
              .footer .btn.btn-link:hover {
                  letter-spacing: 1px;
                  box-shadow: none;
              }
              
              /* .footer .form-control {
                  border-color: rgba(255, 255, 255, 0.5);
              } */
              
              .footer .copyright {
                  padding: 25px 0;
                  font-size: 15px;
                  border-top: 1px solid rgba(256, 256, 256, .1);
              }
              
              .footer .copyright a {
                  color: var(--light);
              }
              
              .footer .footer-menu a {
                  margin-right: 5px;
                  padding-right: 5px;
                  /* border-right: 1px solid rgba(6, 6, 6, 0.1); */
              }
              
              .footer .footer-menu a:last-child {
                  margin-right: 0;
                  padding-right: 0;
                  border-right: none;
              }
              .footer-inner-image img{
                  width: 3rem;
              }
              .contact-icon p a{
                  color: black;
              }
              .play-store-icon a img{
width: 9rem;
              }