.recent-blog-posts .post-box {
                  box-shadow: 0px 0 30px rgba(1, 41, 112, 0.08);
                  transition: 0.3s;
                  height: 100%;
                  overflow: hidden;
                  padding: 30px;
                  border-radius: 8px;
                  position: relative;
                  display: flex;
                  flex-direction: column;
                }
                
                .recent-blog-posts .post-box .post-img {
                  overflow: hidden;
                  margin: -30px -30px 15px -30px;
                  position: relative;
                }
                
                .recent-blog-posts .post-box .post-img img {
                  transition: 0.5s;
                }
                
                .recent-blog-posts .post-box .post-date {
                  font-size: 16px;
                  font-weight: 600;
                  color: rgba(1, 41, 112, 0.6);
                  display: block;
                  margin-bottom: 10px;
                }
                
                .recent-blog-posts .post-box .post-title {
                  font-size: 24px;
                  color: var(--primary);
                  font-weight: 700;
                  margin-bottom: 18px;
                  position: relative;
                  transition: 0.3s;
                }
                .recent-blog-posts .post-box .post-title:hover{
                  color: var(--primary1);
                  
                }
                
                .recent-blog-posts .post-box .readmore {
                  display: flex;
                  align-items: center;
                  font-weight: 600;
                  line-height: 1;
                  transition: 0.3s;
                  text-decoration: none;
                  color: var(--primary);
                }
                
                .recent-blog-posts .post-box .readmore i {
                  line-height: 0;
                  margin-left: 4px;
                  font-size: 18px;
                }
                
                
                .recent-blog-posts .post-box:hover .post-title {
                  color: var(--primary1);
                }
                
                .recent-blog-posts .post-box:hover .post-img img {
                  transform: rotate(6deg) scale(1.2);
                }
                