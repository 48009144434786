.otpcard {
  width: 350px;
  padding: 10px;
  border-radius: 20px;
  background: #fff;
  border: none;
  height: 350px;
  position: relative;
}
.mobile-text {
  color: #989696b8;
  font-size: 15px;
}
.my-form-control {
  margin-right: 12px;
}
.my-form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #002F34;
  outline: 0;
  box-shadow: none;
}
.cursor {
                  cursor: pointer;
                }