.preloader{
                  height: 100vh;
                  width: 100%;
                  /* background: rgb(16, 57, 8); */
                  color: black;
                  position: fixed;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  overflow: hidden;
                  align-items: center;
                  justify-content: center;
                  z-index: 55;
                  display: flex;
}
.preloader .texts-container{
                  height: 100px;
                  width: 400px;
                  color: black;
                  position: fixed;
                  font-weight: 800;
                  font-size: 40px;
                  overflow: hidden;
                  align-items: center;
                  justify-content: space-between;
                  display: flex;
                 
}
 