.trending-image img{
                  height: 250px;
}
.carousel-control-prev {
                  left: -15px;
              }
              .carousel-control-next {
                  right: -15px;
              }
              .carousel-control-next-icon {
  
    background-color: #564c4e;
    border-radius: 50%;
}
.carousel-control-prev-icon {
               
                  background-color: #564c4e;
                  border-radius: 50%;
              }