@charset "UTF-8";
html {
  font-size: 100%;
  scroll-behavior: smooth;
}
:root {
  --primary: #081e1e;
  --secondary: white;

}



body {
  background-color: var(--secondary);
  font-family: "Maven Pro", sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5em;
  color: #606975;
  overflow-x: hidden;
}

a {
  display: inline-block;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

blockquote {
  margin: 0 0 1.3em;
}

p {
  margin-bottom: 15px;
  line-height: 1.8em;
}
p:last-child {
  margin-bottom: 0px;
}
@media only screen and (max-width: 1199px) {
  p {
    line-height: 1.7em;
  }
}

img {
  max-width: 100%;
  height: auto;
}

button:focus, input[type="button"]:focus, input[type="reset"]:focus, input[type="submit"]:focus,
input:focus,
textarea:focus {
  outline: none;
}

button, input[type="button"], input[type="reset"], input[type="submit"] {
  border: none;
  cursor: pointer;
}

input, textarea {
  padding: 12px 25px;
  width: 100%;
}

span {
  display: inline-block;
}

Link, a:focus, a:hover {
  text-decoration: none;
  color: inherit;
}

.row>*{
  position: relative;
}

blockquote {
  background-color: #f9f9f9;
  padding: 20px;
  color: #2f2f35;
  border-radius: 3px;
  font-weight: 500;
  font-style: italic;
  position: relative;
}
blockquote .quote-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 120px;
  opacity: .1;
}

/*-------------------------------------------------
    [ ## padding ]
*/
.pt-10 {
  padding-top: 10px;
}

.pt-20 {
  padding-top: 20px;
}

.pt-30 {
  padding-top: 30px;
}

.pt-40 {
  padding-top: 40px;
}

.pt-50 {
  padding-top: 50px;
}

.pt-60 {
  padding-top: 60px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-100 {
  padding-top: 100px;
}
@media only screen and (max-width: 991px) {
  .pt-100 {
    padding-top: 80px;
  }
}

.pt-120 {
  padding-top: 120px;
}
@media only screen and (max-width: 991px) {
  .pt-120 {
    padding-top: 100px;
  }
}

.pt-150 {
  padding-top: 150px;
}
@media only screen and (max-width: 991px) {
  .pt-150 {
    padding-top: 100px;
  }
}

.pb-10 {
  padding-bottom: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pb-100 {
  padding-bottom: 100px;
}
@media only screen and (max-width: 991px) {
  .pb-100 {
    padding-bottom: 80px;
  }
}

.pb-120 {
  padding-bottom: 120px;
}
@media only screen and (max-width: 991px) {
  .pb-120 {
    padding-bottom: 100px;
  }
}

.pb-150 {
  padding-bottom: 150px;
}
@media only screen and (max-width: 991px) {
  .pb-150 {
    padding-bottom: 100px;
  }
}

.ptb-10 {
  padding: 10px 0;
}

.ptb-20 {
  padding: 20px 0;
}

.ptb-30 {
  padding: 30px 0;
}

.ptb-40 {
  padding: 40px 0;
}

.ptb-50 {
  padding: 50px 0;
}

.ptb-60 {
  padding: 60px 0;
}

.ptb-80 {
  padding: 80px 0;
}

.ptb-100 {
  padding: 100px 0;
}
@media only screen and (max-width: 991px) {
  .ptb-100 {
    padding: 80px 0;
  }
}

.ptb-120 {
  padding: 120px 0;
}
@media only screen and (max-width: 991px) {
  .ptb-120 {
    padding: 100px 0;
  }
}

.ptb-150 {
  padding: 150px 0;
}
@media only screen and (max-width: 991px) {
  .ptb-150 {
    padding: 100px 0;
  }
}

.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-100 {
  margin-top: 100px;
}

.mt-120 {
  margin-top: 120px;
}

.mt-150 {
  margin-top: 150px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-60 {
  margin-bottom: 60px;
}

.mb-80 {
  margin-bottom: 80px;
}
@media only screen and (max-width: 575px) {
  .mb-80 {
    margin-bottom: 40px;
  }
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-120 {
  margin-bottom: 120px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mt-10-none {
  margin-top: -10px;
}

.mt-20-none {
  margin-top: -20px;
}

.mt-30-none {
  margin-top: -30px;
}

.mt-40-none {
  margin-top: -40px;
}

.mt-50-none {
  margin-top: -50px;
}

.mt-60-none {
  margin-top: -60px;
}

.mt-80-none {
  margin-top: -80px;
}

.mt-100-none {
  margin-top: -100px;
}

.mt-120-none {
  margin-top: -120px;
}

.mt-150-none {
  margin-top: -150px;
}

.mb-10-none {
  margin-bottom: -10px;
}

.mb-20-none {
  margin-bottom: -20px;
}

.mb-30-none {
  margin-bottom: -30px;
}

.mb-40-none {
  margin-bottom: -40px;
}

.mb-50-none {
  margin-bottom: -50px;
}

.mb-60-none {
  margin-bottom: -60px;
}

.mb-80-none {
  margin-bottom: -80px;
}
@media only screen and (max-width: 575px) {
  .mb-80-none {
    margin-bottom: -40px;
  }
}

.mb-100-none {
  margin-bottom: -100px;
}

.mb-120-none {
  margin-bottom: -120px;
}

.mb-150-none {
  margin-bottom: -150px;
}

/*-------------------------------------------------
    [ ## color ]
*/
.bg--primary {
  background-color: #7367f0 !important;
}

.bg--secondary {
  background-color: #868e96;
}

.bg--success {
  background-color: #28c76f;
}

.bg--danger {
  background-color: #ea5455;
}

.bg--warning {
  background-color: #ff9f43;
}

.bg--info {
  background-color: #1e9ff2;
}

.bg--dark {
  background-color: #10163A;
}

.bg--base {
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
}

.text--primary {
  color: #7367f0;
}

.text--secondary {
  color: #868e96;
}

.text--success {
  color: #28c76f;
}

.text--danger {
  color: #ea5455;
}

.text--warning {
  color: #ff9f43;
}

.text--info {
  color: #1e9ff2;
}

.text--dark {
  color: #10163A;
}

.text--base {
  color: #2ecc71 !important;
}



.border--primary {
  border: #7367f0;
}

.border--secondary {
  border: 1px solid #868e96;
}

.border--success {
  border: 1px solid #28c76f;
}

.border--danger {
  border: 1px solid #ea5455;
}

.border--warning {
  border: 1px solid #ff9f43;
}

.border--info {
  border: 1px solid #1e9ff2;
}

.border--dark {
  border: 1px solid #10163A;
}

.border--base {
  border: 1px solid #e1e7ec !important;
}

.section--bg {
  background-color: #1e2746 !important;
}

.bg--gray {
  background-color: #f9f9f9;
}

.border--rounded {
  border-radius: 3px !important;
}

.border--capsule {
  border-radius: 100px;
}

.badge {
  padding-bottom: 5px;
}

/*-------------------------------------------------
    [ ## Table ]
*/
.table-area {
  margin-top: -15px;
  margin-bottom: -15px;
}

.custom-table {
  width: 100%;
  border-spacing: 0 15px;
  border-collapse: separate;
}
@media only screen and (max-width: 991px) {
  .custom-table thead {
    display: none;
  }
}
.custom-table thead tr {
  background-color: #1e2746;
}
.custom-table thead tr th {
  border: none;
  font-weight: 500;
  text-align: center;
  color: var(--secondary);
  font-size: 14px;
  padding: 10px 20px;
}
.custom-table thead tr th:first-child {
  border-radius: 5px 0 0 5px;
}
.custom-table thead tr th:last-child {
  border-radius: 0 5px 5px 0;
}
.custom-table tbody tr {
  background-color: var(--secondary);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.09);
  border-radius: 5px;
  transition: all 0.5s;
}
.custom-table tbody tr:hover {
  transform: translateY(-7px);
}
.custom-table tbody tr td {
  border: none;
  font-weight: 600;
  text-align: center;
  color: #606975;
  font-size: 14px;
  padding: 10px 20px;
}
.custom-table tbody tr td .author-info {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.custom-table tbody tr td .author-info .thumb {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.25);
}
.custom-table tbody tr td .author-info .thumb img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
}
.custom-table tbody tr td .author-info .content {
  padding-left: 15px;
  width: calc(100% - 45px);
}
.custom-table tbody tr td a {
  font-weight: 600;
  font-size: 14px;
}
.custom-table tbody tr td .btn {
  border-radius: 3px;
}
.custom-table tbody tr td:first-child {
  border-radius: 5px 0 0 5px;
}
.custom-table tbody tr td:last-child {
  border-radius: 0 5px 5px 0;
}
@media only screen and (max-width: 991px) {
  .custom-table tbody tr td {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    border-bottom: 1px solid #e1e7ec;
  }
  .custom-table tbody tr td:last-child {
    border: none;
  }
  .custom-table tbody tr td::before {
    content: attr(data-label);
    margin-right: auto;
    color: var(--primary);
    width: 50%;
    text-align: left;
  }
}

/*-------------------------------------------------
    [ ## tab ]
*/
.nav-tabs {
  margin: 0;
  border: 0;
  border-bottom: 1px solid #e1e7ec;
}
.nav-tabs .nav-link {
  padding: 5px 15px;
  border: 0;
  border-bottom: 1px solid transparent;
  color: #606975;
  font-weight: 500;
  font-size: 14px;
  background-color: transparent;
}
.nav-tabs .nav-link.active {
  color: var(--secondary);
  border-bottom: 1px solid var(--primary);
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
}
.nav-tabs .nav-link:not(:last-child) {
  margin-right: 20px;
}
@media only screen and (max-width: 420px) {
  .nav-tabs .nav-link:not(:last-child) {
    margin-right: 0;
  }
}

/*-------------------------------------------------
    [ ## scrollbar ]
*/
*::-webkit-scrollbar-track {
  -webkit-box-shadow: inset -5px -5px 8px -1px rgba(255, 255, 255, 0.7), 5px 5px 8px -1px rgba(0, 0, 0, 0.065);
  box-shadow: inset -5px -5px 8px -1px rgba(255, 255, 255, 0.7), 5px 5px 8px -1px rgba(0, 0, 0, 0.065);
  background-color: #eaf7e4;
}

*::-webkit-scrollbar {
  width: 6px;
  background-color: #eaf7e4;
}

*::-webkit-scrollbar-button {
  background-color: var(--primary);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--primary);
}

/*-------------------------------------------------
    [ ## scrollToTop ]
*/
.scrollToTop {
  position: fixed;
  bottom: 0;
  right: 30px;
  width: 45px;
  height: 45px;
 background: var(--primary);
  color: var(--secondary);
  line-height: 40px;
  font-size: 20px;
  text-align: center;
  border-radius: 50%;
  z-index: 99;
  cursor: pointer;
  transition: all 1s;
  transform: translateY(100%);
}
.scrollToTop img {
  width: 25px;
}

.scrollToTop.active {
  bottom: 30px;
  transform: translateY(0%);
}

.scrollToTop:hover, .scrollToTop:focus {
  color: var(--secondary);
}

/*-------------------------------------------------
    [ ## slider ]
*/
.swiper-notification {
  display: none;
}

.swiper-pagination {
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 80px;
}
.swiper-pagination .swiper-pagination-bullet {
  height: 3px;
  width: 25px;
  border-radius: 0;
  background-color: #2f2f35;
  opacity: 1;
}
.swiper-pagination .swiper-pagination-bullet-active {
  background-color: var(--primary);
}

.slider-next, .slider-prev {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 18px;
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  border-radius: 50%;
  color: var(--secondary);
  display: inline-block;
  text-align: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9;
}

.slider-next {
  right: -20px;
}

.slider-prev {
  left: -20px;
}

/*-------------------------------------------------
    [ ## pagination ]
*/
.pagination {
  display: flex;
  flex-wrap: wrap;
  margin-top: 60px;
  border-top: 1px solid #e1e7ec;
  border-radius: 0;
}
.pagination .page-item {
  text-align: center;
  padding: 20px 3px 3px 3px;
}
.pagination .page-item a, .pagination .page-item span {
  width: 30px;
  height: 30px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background: transparent;
  border: 1px solid #e1e7ec;
  color: #2f2f35;
  padding: 0;
  line-height: 30px;
  font-weight: 500;
  font-size: 14px;
  display: block;
  margin: 0;
}
.pagination .page-item.disabled span {
  background: rgba(44, 221, 155, 0.4);
  border: 1px solid rgba(44, 221, 155, 0.1);
  color: var(--secondary);
}
.pagination .page-item .page-link {
  transition: all 0.3s;
}
.pagination .page-item.active .page-link, .pagination .page-item:hover .page-link {
 background: var(--primary);
  border-color: var(--primary);
  color: var(--secondary);
}

/*-------------------------------------------------
    [ ## widget ]
*/
.widget-title {
  font-size: 15px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e1e7ec;
  text-transform: uppercase;
}

/*-------------------------------------------------
    [ ## tab ]
*/
.tab-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
  text-align: center;
  border: none;
}
.tab-menu .nav-item {
  background-color: transparent;
  border: 2px solid #1e2746;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-right: 15px;
  color: #2f2f35;
  padding: 3px 25px;
  transition: all 0.3s;
}
.tab-menu .nav-item:hover {
  background-color: #1e2746;
  color: var(--secondary);
}
.tab-menu .nav-item.active {
  background-color: var(--primary);
  border: 2px solid var(--primary);
  color: var(--secondary);
}

/*-------------------------------------------------
    [ ## card ]
*/
.custom--card {
  border: none;
  box-shadow: 7px 5px 30px 0px rgba(72, 73, 121, 0.15);
}
.custom--card .card-header {
  background-color: #1e2746;
  padding: 0.8rem 1rem;
}
.custom--card .card-header .card-title {
  color: var(--secondary);
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 0;
  font-weight: 500;
}
.custom--card .card-header .card-title i {
  margin-right: 5px;
}
.custom--card .card-header .card-btn a {
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
}
.custom--card .card-header .card-btn a i {
  margin-right: 5px;
}
.custom--card .card-body {
  padding: 40px;
}
@media only screen and (max-width: 991px) {
  .custom--card .card-body {
    padding: 20px;
  }
}
.custom--card .card-body .card-form-wrapper .card-label {
  text-transform: uppercase;
}
.custom--card .card-body .card-form-wrapper input {
  background-color: #f9f9f9;
  border-radius: 3px;
  padding: 10px 15px;
}
.custom--card .card-body .card-form-wrapper textarea {
  height: auto;
  min-height: 120px;
  color: #606975;
}

/*-------------------------------------------------
    [ ## Heading ]
*/
h1, h2, h3, h4, h5, h6 {
  clear: both;
  line-height: 1.2em;
  color: #2f2f35;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  font-family: "Maven Pro", sans-serif;
}

h1 {
  font-size: 35px;
  font-weight: 500;
}
@media only screen and (max-width: 991px) {
  h1 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 575px) {
  h1 {
    font-size: 32px;
  }
}

h2 {
  font-size: 26px;
  font-weight: 500;
}
@media only screen and (max-width: 991px) {
  h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 575px) {
  h2 {
    font-size: 20px;
  }
}

h3 {
  font-size: 24px;
  font-weight: 500;
}
@media only screen and (max-width: 991px) {
  h3 {
    font-size: 20px;
  }
}

h4 {
  font-size: 20px;
  font-weight: 500;
}
@media only screen and (max-width: 991px) {
  h4 {
    font-size: 16px;
  }
}

h5 {
  font-size: 14px;
  font-weight: 500;
}
@media only screen and (max-width: 991px) {
  h5 {
    font-size: 14px;
  }
}

h6 {
  font-size: 12px;
  font-weight: 500;
}
@media only screen and (max-width: 991px) {
  h6 {
    font-size: 12px;
  }
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a {
  color: inherit;
  text-decoration: none;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover {
  color: inherit;
  text-decoration: none;
}
.order-btn a {
  padding: 3px 5px;
  font-size: 12px;
  text-decoration: none;
}
.item-card-footer a {
  margin-left: 6px;
  text-decoration: none;
}
.section-header {
  margin-bottom: 30px;
  border-bottom: 1px solid #e1e7ec;
}
.section-header.white .section-title {
  color: var(--secondary);
}
.section-header .section-title {
  position: relative;
  font-weight: 600;
  color: #2f2f35;
  margin-bottom: 10px;
  margin-top: -17px;
}
@media only screen and (max-width: 575px) {
  .section-header p {
    font-size: 15px;
  }
}

/*--------------------------------------------------------------
# Components
--------------------------------------------------------------*/
/*-------------------------------------------------
    [ ## Buttons ]
*/
input[type="submit"]:hover {
  color: var(--secondary);
}

button, input[type="button"], input[type="reset"], input[type="submit"],
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  --webkit-appearance: button;
}
button:focus, input[type="button"]:focus, input[type="reset"]:focus, input[type="submit"]:focus,
input[type="button"]:focus,
input[type="reset"]:focus,
input[type="submit"]:focus {
  outline: none;
}

button::-moz-focus-inner, input[type="button"]::-moz-focus-inner, input[type="reset"]::-moz-focus-inner, input[type="submit"]::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.btn {
  -webkit-transition: all 0.3s ease 0.02s;
  transition: all 0.3s ease 0.02s;
}

.btn:active,
.btn:focus {
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.125) inset;
}

.btn {
  border: 0px solid;
  border-radius: 0px;
  font-weight: 500;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
@media only screen and (max-width: 767px) {
  .btn {
    font-size: 14px;
  }
}

.btn-rounded {
  border-radius: 3px;
}

.btn-capsule {
  border-radius: 100px;
}

/*-------------------------------------------------
    [ ## custom btn ]
*/
.btn--primary {
  background-color: #7367f0;
}

.btn--secondary {
  background-color: #868e96;
}

.btn--success {
  background-color: #28c76f;
}

.btn--danger {
  background-color: #ea5455;
}

.btn--warning {
  background-color: #ff9f43;
}

.btn--info {
  background-color: #1e9ff2;
}

.btn--dark {
  background-color: #10163A;
}

.badge--primary {
  background-color: #7367f0;
}

.badge--secondary {
  background-color: #868e96;
}

.badge--success {
  background-color: #28c76f;
}

.badge--danger {
  background-color: #ea5455;
}

.badge--warning {
  background-color: #ff9f43;
}

.badge--info {
  background-color: #1e9ff2;
}

.badge--dark {
  background-color: #10163A;
}

.border--primary {
  border: 1px solid #7367f0;
}

.border--secondary {
  border: 1px solid #868e96;
}

.border--success {
  border: 1px solid #28c76f;
}

.border--danger {
  border: 1px solid #ea5455;
}

.border--warning {
  border: 1px solid #ff9f43;
}

.border--info {
  border: 1px solid #1e9ff2;
}

.border--dark {
  border: 1px solid #10163A;
}

.btn--base {
  position: relative;
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  color: var(--secondary);
  text-align: center;
  padding: 6px 20px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px;
  z-index: 2;
  -webkit-transition: all ease 0.5s;
  -moz-transition: all ease 0.5s;
  transition: all ease 0.5s;
}
.btn--base.active {
  background: transparent;
  color: #606975;
  border: 1px solid #e1e7ec;
}
.btn--base.active:focus, .btn--base.active:hover {
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  border: 1px solid var(--primary);
  box-shadow: none;
}
.btn--base:focus, .btn--base:hover {
  color: var(--secondary);
  box-shadow: 0 10px 20px rgba(46, 204, 113, 0.4);
}
@media only screen and (max-width: 575px) {
  .btn--base {
    padding: 6px 20px;
    font-size: 14px;
  }
}

.custom-btn {
  color: var(--primary);
  font-weight: 500;
  font-size: 13px;
  display: block;
}
.custom-btn:hover {
  color: var(--primary);
}

/*-------------------------------------------------
    [ ## Fields ]
*/
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  outline: none;
}

input, textarea {
  padding: 12px 20px;
}
input::placeholder, textarea::placeholder {
  color: #606975;
}

textarea {
  display: block;
  width: 100%;
  display: block;
  min-height: 120px;
}

input,
select,
textarea {
  border: 1px solid #e1e7ec;
  vertical-align: baseline;
  font-size: 100%;
  color: #606975;
}

label {
  font-size: 14px;
  color: #606975;
  font-family: "Maven Pro", sans-serif;
  font-weight: 500;
  margin-bottom: 10px;
  display: block;
}

/*-------------------------------------------------
    [ ## Forms ]
*/
select {
  outline: none;
  cursor: pointer;
}

option {
  color: #2f2f35;
}

.form-group {
  margin-bottom: 20px;
}

.form-control {
  border: 1px solid #e1e7ec;
  font-size: 14px;
  font-weight: 500;
  height: 45px;
  color: #606975;
  appearance: auto;
  background-color: #f9f9f9;
}
.form-control:focus {
  box-shadow: none;
  border: 1px solid #e1e7ec;
  color: #606975;
  background-color: #f9f9f9;
}

.country-code .input-group-text{
  background: transparent;
  border: 1px solid #e1e7ec;
  border-radius: 50px 0 0 50px;
  height: 40px;
  color: #606975;
}

.input-group-append, .input-group-prepend {
  display: -ms-flexbox;
  display: flex;
}

.input-group-text {
  border: none;
  font-size: 14px;
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  color: var(--secondary);
  height: 45px;
  border-radius: 0 3px 3px 0;
  font-weight: 500;
}

.form--control {
  background-color: transparent;
  border: 1px solid #e1e7ec;
  box-shadow: none;
  height: 40px;
  padding: 0 20px;
  color: #606975;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 600;
  width: 100%;
}
.form--control:focus {
  background-color: transparent;
  border: 1px solid #e1e7ec;
  box-shadow: none;
  color: #606975;
}
.form--control::placeholder {
  color: #606975;
}

.custom-check-group {
  display: block;
  margin-bottom: 12px;
}
.custom-check-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}
.custom-check-group input:checked + label::before {
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
}
.custom-check-group input:checked + label::after {
  content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 11px;
    width: 6px;
    height: 16px;
    border: solid #fff;
    border-width: 0 2px 3px 0px;
    transform: rotate(46deg);
}
.custom-check-group label {
  position: relative;
  cursor: pointer;
}
.custom-check-group label::before {
  content: '';
  /* -webkit-appearance: none; */
  background: #FFC107;
  border-radius: 4px;
  padding: 14px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  top: 0px;
}

.forgot-password a {
  color: var(--primary);
  font-weight: 600;
}

.submit-btn {
  padding: 8px 30px;
  color: var(--secondary);
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  font-weight: 500;
  font-size: 14px;
  border-radius: 3px;
}
@media only screen and (max-width: 991px) {
  .submit-btn {
    font-size: 14px;
    padding: 10px 20px;
  }
}

.cancel-btn {
  padding: 8px 30px;
  color: var(--secondary);
  background: #ea5455;
  font-weight: 500;
  font-size: 14px;
  border-radius: 3px;
}
@media only screen and (max-width: 991px) {
  .cancel-btn {
    font-size: 14px;
    padding: 10px 20px;
  }
}

/*-------------------------------------------------
    [ ## Chosen Select ]
*/
.chosen-select {
  z-index: 1;
  width: 100%;
  position: relative;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  padding: 10px 30px 10px 20px;
}

.chosen-container {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  width: 100%;
}
.chosen-container .chosen-single {
  margin: 0;
  z-index: 2;
  width: 100%;
  height: auto;
  outline: none;
  background: none;
  font-size: 14px;
  box-shadow: none;
  padding: 10px 12px;
  border-radius: 3px;
  vertical-align: top;
  display: inline-block;
  border: 1px solid #e1e7ec;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.chosen-container .chosen-single span {
  margin: 0;
  width: 100%;
  color: #606975;
  line-height: initial;
  font-weight: 500;
  font-size: 13px;
}
.chosen-container .chosen-search input {
  height: 35px !important;
  border-color: #e1e7ec !important;
  border-radius: 3px !important;
}
.chosen-container .chosen-results {
  margin: 0;
  padding: 0;
  width: 100%;
  float: left;
  line-height: 34px;
}
.chosen-container .chosen-results li {
  font-weight: 500;
}
.chosen-container .chosen-results li.highlighted {
 background: var(--primary);
}
.chosen-container .chosen-drop {
  border-radius: 5px;
}

.chosen-container-active.chosen-with-drop .chosen-single {
  border-radius: 5px;
  border: 1px solid #e1e7ec;
  background: transparent;
  box-shadow: none;
}

.btn-toggle {
  border-radius: 0 3px 3px 0;
  position: absolute !important;
  top: 0;
  right: 0;
  width: 45px;
  height: 100%;
  z-index: 9;
  border: none !important;
  color: #606975;
}

.input-group {
  margin-bottom: 10px;
}

/*-------------------------------------------------
    [ ## Overlay Element ]
*/
.bg_img {
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}

.bg-fixed {
  background-attachment: fixed;
}

.bg-overlay-white {
  position: relative;
}
.bg-overlay-white:before {
  content: "";
  position: absolute;
  background: var(--secondary);
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: .7;
}
.bg-overlay-white > div {
  position: relative;
  z-index: 2;
}

@-webkit-keyframes left {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@keyframes left {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}
@-webkit-keyframes right {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@keyframes right {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}
@-webkit-keyframes top {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@keyframes top {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}
@-webkit-keyframes bottom {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
@keyframes bottom {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}
@-webkit-keyframes ripple {
  0%,35% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: .8;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}
@keyframes ripple {
  0%,35% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 1;
  }

  50% {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    opacity: .8;
  }

  100% {
    opacity: 0;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
}
@keyframes shakes {
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(40px);
  }

  100% {
    transform: translateX(0px);
  }
}
@keyframes up_down {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(0px);
  }
}
/*--------------------------------------------------------------
# Layout
--------------------------------------------------------------*/
/*--------------------------------------------------------------
    [ ## Header ]
--------------------------------------------------------------*/
/*-------------------------------------------------
    [ ## Preloader ]
*/
.preloader {
  position: fixed;
  left: 0;
  width: 0;
  height: 100%;
  width: 100%;
  text-align: center;
  z-index: 9999999;
  background-color: var(--secondary);
}

.box-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99999;
}

.loader {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 150px;
}

.loader:before {
  content: "";
  display: block;
  padding-top: 100%;
}

.loader.animate .circle {
  animation: rotate 2s linear infinite;
}

.loader.animate .path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
}

.loader.animate .line {
  animation: line 0.5s ease-in-out;
}

.circular {
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-linecap: round;
  stroke: var(--primary);
}

.line {
  stroke-dasharray: 0;
  stroke-dashoffset: 0;
  transform: rotate(45deg);
  stroke: #1e2746;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes color {
  0% {
    stroke: var(--primary);
  }

  100% {
    stroke: var(--primary);
  }
}
@keyframes line {
  to {
    stroke-dashoffset: 1000;
  }
}
::selection {
  background-color: var(--primary);
  color: var(--secondary);
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 620px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 1020px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1390px;
  }
}
/*-------------------------------------------------
    [ ## Header ]
*/
.header-section {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background-color: transparent;
}
@media only screen and (max-width: 991px) {
  .header-section .header-top-area .container {
    max-width: 100%;
  }
}

.header {
  background-color: transparent;
  width: 100%;
  z-index: 99;
  -webkit-transition: all ease 1s;
  transition: all ease 1s;
}
.header.active {
  transform: translateY(0%);
  position: fixed;
  top: 0;
  left: 0;
  animation-name: fadeInDown;
  animation-duration: 1s;
  transition: all 1s;
}

.header-section.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: var(--secondary);
}
.header-section.header-fixed .header-top-area {
  display: none;
}
.header-section.header-fixed .header-bottom-area {
  background-color: transparent;
}

.header-bottom-area {
  position: relative;
  border-bottom: 1px solid #e1e7ec;
  padding: 0 100px;
}
@media only screen and (max-width: 1400px) {
  .header-bottom-area {
    padding: 0 30px;
  }
}
@media only screen and (max-width: 991px) {
  .header-bottom-area {
    padding: 5px 20px;
  }
}
@media only screen and (max-width: 575px) {
  .header-bottom-area {
    padding: 5px 0;
  }
}
.header-bottom-area .navbar-expand-lg {
  background-color: transparent;
  padding: 0 !important;
}
@media only screen and (max-width: 991px) {
  .header-bottom-area .navbar-expand-lg {
    padding: 0 !important;
  }
}
@media (max-width: 767px) {
  .header-bottom-area .navbar-collapse {
    max-height: 320px;
    overflow: auto;
  }
}
@media only screen and (max-width: 991px) {
  .header-bottom-area .navbar-collapse {
    background-color: #f9f9f9;
  }
}
@media (max-width: 991px) {
  .header-bottom-area .navbar-collapse .main-menu {
    padding: 15px 0;
    background-color: #f9f9f9;
  }
  .header-bottom-area .menu_has_children .sub-menu {
    display: none !important;
  }
  .header-bottom-area .menu_has_children .sub-menu li {
    width: 100%;
  }
  .header-bottom-area .navbar-collapse .main-menu .menu_has_children.show .sub-menu,
  .header-bottom-area .navbar-collapse .main-menu .menu_has_children.show .sub-menu {
    display: flex !important;
    flex-wrap: wrap;
  }
}
.header-bottom-area .navbar-collapse .main-menu li:last-child .sub-menu {
  left: auto;
  right: 0;
}
.header-bottom-area .navbar-collapse .main-menu li {
  position: relative;
  padding-right: 30px;
}
@media only screen and (max-width: 1199px) {
  .header-bottom-area .navbar-collapse .main-menu li {
    padding-right: 18px;
  }
}
.header-bottom-area .navbar-collapse .main-menu li:last-child {
  padding-right: 0;
  margin-right: 0;
}
.header-bottom-area .navbar-collapse .main-menu li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.header-bottom-area .navbar-collapse .main-menu li.menu_has_children {
  position: relative;
}
.header-bottom-area .navbar-collapse .main-menu li.menu_has_children i {
  font-size: 12px;
  margin-left: 5px;
}
@media (max-width: 991px) {
  .header-bottom-area .navbar-collapse .main-menu li.menu_has_children::before {
    top: 12px;
    right: 15px;
  }
}
.header-bottom-area .navbar-collapse .main-menu li a {
  position: relative;
  padding: 30px 0 30px 0;
  font-weight: 500;
  align-items: center;
  display: inline-block;
  font-size: 14px;
  text-transform: uppercase;
  font-family: "Maven Pro", sans-serif;
  transition: all 0.9s;
}
.header-bottom-area .navbar-collapse .main-menu li a::after {
  position: absolute;
  content: '';
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background-color: var(--primary);
  transition: all 0.9s;
}
.header-bottom-area .navbar-collapse .main-menu li a:hover, .header-bottom-area .navbar-collapse .main-menu li a.active {
  color: var(--primary);
}
.header-bottom-area .navbar-collapse .main-menu li a:hover::after, .header-bottom-area .navbar-collapse .main-menu li a.active::after {
  width: 100%;
}
.header-bottom-area .header-section.header-fixed .navbar-collapse .main-menu li a {
  padding: 30px 18px 30px 0;
}
@media (max-width: 991px) {
  .header-bottom-area .navbar-collapse .main-menu li a {
    padding: 12px 15px !important;
    display: block;
  }
}
.header-bottom-area .navbar-collapse .main-menu li .sub-menu {
  position: absolute;
  top: 105%;
  left: 0;
  width: 170px;
  background-color: #1e2746;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
  border-top: 2px solid var(--primary);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  z-index: -9;
  margin-left: 0;
}
.header-bottom-area .navbar-collapse .main-menu li .sub-menu li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-right: 0;
  transition: all ease 0.5s;
  position: relative;
  z-index: 2;
}
.header-bottom-area .navbar-collapse .main-menu li .sub-menu li:last-child {
  border-bottom: 0px solid;
}
.header-bottom-area .navbar-collapse .main-menu li .sub-menu li:hover a {
  color: var(--secondary);
}
.header-bottom-area .navbar-collapse .main-menu li .sub-menu li a {
  color: var(--secondary);
  width: 100%;
  padding: 5px 20px;
  font-size: 12px;
  display: block;
  transition: all ease 0.3s;
}
.header-bottom-area .navbar-collapse .main-menu li .sub-menu li a::after {
  position: unset;
  content: none;
}
.header-bottom-area .navbar-collapse .main-menu li .sub-menu .header-section.header-fixed .navbar-collapse .main-menu li .sub-menu li a {
  padding: 8px 20px;
}
@media (max-width: 991px) {
  .header-bottom-area .navbar-collapse .main-menu li .sub-menu .navbar-collapse .main-menu li .sub-menu {
    position: initial;
    opacity: 1;
    visibility: visible;
    display: none;
    -webkit-transition: none;
    transition: none;
  }
}
.header-bottom-area .navbar-collapse .main-menu li .sub-menu .navbar-collapse .main-menu li:last-child .sub-menu {
  left: auto;
  right: 0;
}

.language-select-area {
  position: relative;
  z-index: 99;
}
@media only screen and (max-width: 991px) {
  .language-select-area {
    margin-left: 15px;
    margin-bottom: 15px;
  }
}

.language-select {
  font-size: 14px;
  border: 1px solid #e1e7ec;
  font-weight: 500;
  border-radius: 3px;
  padding: 10px 10px;
  background-color: transparent;
}

.site-logo {
  max-width: 190px;
}
@media only screen and (max-width: 420px) {
  .site-logo img {
    height: 30px;
  }
}

.header-social li {
  display: inline-block;
}
.header-social li a {
  color: var(--secondary);
  font-size: 12px;
}
@media only screen and (max-width: 991px) {
  .header-social li a {
    font-size: 12px;
  }
}
.header-social li + li {
  margin-left: 10px;
}

.header-action {
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
  margin-left: 5px;
}
@media only screen and (max-width: 991px) {
  .header-action {
    margin: 0;
    margin-left: 12px;
    margin-bottom: 20px;
    display: block;
  }
}
.header-action a {
  font-size: 14px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  margin: 5px;
}
.header-action a i {
  font-size: 16px;
  margin-right: 3px;
}
@media only screen and (max-width: 991px) {
  .header-action a {
    display: inline-block;
  }
}

.header-short-menu {
  padding: 10px 100px;
  border-bottom: 1px solid #e1e7ec;
  box-shadow: 0 1px 3px -1px gray;
}
@media only screen and (max-width: 1400px) {
  .header-short-menu {
    padding: 0px 50px;
  }
}
@media only screen and (max-width: 991px) {
  .header-short-menu {
    padding: 0;
    border: none;
  }
}

.short-menu {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.short-menu.open {
  left: 0;
}
@media only screen and (max-width: 991px) {
  .short-menu {
    padding-top: 20px;
    position: fixed;
    display: block;
    top: 0;
    left: -100%;
    background-color: var(--secondary);
    width: 220px;
    z-index: 99;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
  }
}
.short-menu li {
  font-size: 14px;
  font-weight: 500;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
@media only screen and (max-width: 991px) {
  .short-menu li {
    padding: 6px 10px;
    border-bottom: 1px solid #e1e7ec;
  }
  .short-menu li:first-child, .short-menu li:last-child {
    border: none;
  }
}
.short-menu li:hover {
  color: var(--primary);
}

.short-menu-close-btn-area {
  display: none;
}
@media only screen and (max-width: 991px) {
  .short-menu-close-btn-area {
    display: inline-block;
  }
}

.short-menu-close-btn {
  position: absolute;
  top: 0px;
  right: 0;
  z-index: 91;
  background-color: var(--primary);
  font-weight: 500;
  color: var(--secondary);
  padding: 3px 15px;
  display: none;
}
@media only screen and (max-width: 991px) {
  .short-menu-close-btn {
    display: block;
  }
}

.navbar-toggler:focus {
  box-shadow: none;
}

.navbar-toggler span {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  color: var(--primary);
  font-size: 30px;
}
@media only screen and (max-width: 991px) {
  .navbar-toggler span {
    font-size: 22px;
  }
}

.short-menu-open-btn {
  background-color: var(--primary);
  color: var(--secondary);
  padding: 3px 15px;
  border-radius: 3px;
  display: none;
}
@media only screen and (max-width: 991px) {
  .short-menu-open-btn {
    display: block;
  }
}

/*--------------------------------------------------------------
    [ ## Introduction ]
--------------------------------------------------------------*/
.banner-section {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media only screen and (max-width: 991px) {
  .banner-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
.banner-section .banner-content .title {
  margin-bottom: 10px;
  font-weight: 600;
}

.breadcrumb {
  background-color: transparent;
  padding: 0;
  justify-content: center;
}

.breadcrumb li {
  font-family: "Maven Pro", sans-serif;
}

.breadcrumb-item a {
  font-weight: 600;
  font-size: 14px;
  position: relative;
  z-index: 2;
  color: var(--primary);
}

.breadcrumb-item.active {
  font-weight: 600;
  color: #606975;
  font-size: 14px;
  position: relative;
  z-index: 2;
}

.breadcrumb-item.active::before {
  content: "/";
  font-weight: 600;
  color: var(--primary);
  font-size: 14px;
  left: 0;
}

/*--------------------------------------------------------------
    [ ## Footer ]
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Footer-block
--------------------------------------------------------------*/
.footer-wrapper {
  padding: 0 100px;
}
@media only screen and (max-width: 1400px) {
  .footer-wrapper {
    padding: 0 40px;
  }
}
@media only screen and (max-width: 991px) {
  .footer-wrapper {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 575px) {
  .footer-wrapper {
    padding: 0;
  }
}
.footer-wrapper.open .footer-toggle {
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.footer-wrapper.open .right-icon::before {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  -ms-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
}
.footer-wrapper.open .footer-bottom-area {
  display: block;
}

.footer-toggle {
  color: var(--secondary);
  cursor: pointer;
  padding-bottom: 15px;
  margin-bottom: 0;
  border-bottom: 1px solid transparent;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 500;
}
.footer-toggle .right-icon {
  display: block;
  width: 35px;
  height: 35px;
  position: relative;
  left: 0;
}
.footer-toggle .right-icon::before, .footer-toggle .right-icon::after {
  position: absolute;
  content: '';
  background: var(--secondary);
  -webkit-transition: all ease 0.3s;
  -moz-transition: all ease 0.3s;
  transition: all ease 0.3s;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.footer-toggle .right-icon::before {
  width: 2px;
  height: 14px;
  top: 50%;
  left: 50%;
}
.footer-toggle .right-icon::after {
  width: 14px;
  height: 2px;
  top: 50%;
  left: 50%;
}

.footer-bottom-area {
  padding: 40px 0;
  display: none;
}

.footer-widget .title {
  color: var(--secondary);
  font-size: 20px;
  margin-bottom: 12px;
}
.footer-widget p {
  font-size: 14px;
  color: var(--secondary);
}

.footer-links li {
  color: var(--secondary);
  font-size: 14px;
  padding-bottom: 8px;
  padding-left: 25px;
  position: relative;
  transition: all 0.5s;
}
.footer-links li:hover {
  color: var(--primary);
  margin-left: 5px;
}
.footer-links li:hover::before {
  color: var(--primary);
}
.footer-links li::before {
  position: absolute;
  content: "\f101";
  font-family: 'Line Awesome Free';
  top: 0;
  left: 0;
  color: var(--secondary);
  font-size: 14px;
  font-weight: 900;
  transition: all 0.5s;
}
@media only screen and (max-width: 575px) {
  .footer-links li {
    font-size: 12px;
  }
}

.subscribe-form {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.subscribe-form input {
  padding: 10px 25px;
  width: calc(100% - 55px);
  border: none;
  font-size: 14px;
  font-weight: 500;
  border-radius: 3px 0 0 3px;
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  -ms-border-radius: 3px 0 0 3px;
  -o-border-radius: 3px 0 0 3px;
  min-height: 50px;
}
.subscribe-form button, .subscribe-form input[type="button"], .subscribe-form input[type="reset"], .subscribe-form input[type="submit"] {
  width: 55px;
  background-color: var(--primary);
  color: var(--secondary);
  border-radius: 0 3px 3px 0;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  -ms-border-radius: 0 3px 3px 0;
  -o-border-radius: 0 3px 3px 0;
}

@media only screen and (max-width: 575px) {
  .footer-social {
    margin-top: 15px;
  }
}
.footer-social li {
  display: inline-block;
}
.footer-social li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  display: inline-block;
  text-align: center;
  border: 2px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: var(--secondary);
  font-size: 12px;
  transition: all 0.3s;
}
.footer-social li a:hover, .footer-social li a.active {
  background-color: var(--primary);
}
.footer-social li + li {
  margin-left: 10px;
}

.copyright-area {
  background-color: #1e2746;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding: 15px 0;
}
@media only screen and (max-width: 575px) {
  .copyright-area {
    display: block !important;
    text-align: center;
  }
}

.copyright p {
  margin-bottom: 0;
  color: var(--secondary);
  font-size: 13px;
  text-transform: uppercase;
}
@media only screen and (max-width: 575px) {
  .copyright p {
    font-size: 12px;
  }
}

/*--------------------------------------------------------------
# Pages
--------------------------------------------------------------*/
/*--------------------------------------------------------------
    [ ## team ]
--------------------------------------------------------------*/
/*--------------------------------------------------------------
    [ ## Testimonial ]
--------------------------------------------------------------*/
/*--------------------------------------------------------------
    [ ## Sections ]
--------------------------------------------------------------*/
/*--------------------------------------------------------------
    [ ## Sections ]
--------------------------------------------------------------*/
/*-------------------------------------------------
    [ ### item block ]
*/
.all-sections {
  min-height: calc(100vh - 307px);
}
@media only screen and (max-width: 991px) {
  .all-sections {
    min-height: calc(100vh - 238px);
  }
}

.item-top-area {
  padding-bottom: 20px;
  /* border-bottom: 1px solid #e1e7ec; */
  margin-bottom: 30px;
}
@media only screen and (max-width: 991px) {
  .item-top-area {
    display: block !important;
  }
}

.item-wrapper {
  width: 68%;
}
@media only screen and (max-width: 575px) {
  .item-wrapper {
    display: block !important;
  }
}
@media only screen and (max-width: 991px) {
  .item-wrapper {
    width: 100%;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .item-wrapper-left {
    display: block !important;
  }
}

.item-wrapper-right {
  width: calc(100% - 60%);
}
@media only screen and (max-width: 991px) {
  .item-wrapper-right {
    width: 100%;
  }
}

.item-sorting {
  margin-right: 30px;
}
@media only screen and (max-width: 575px) {
  .item-sorting {
    margin-right: 0;
  }
}

.item-value {
  font-size: 13px;
  font-weight: 500;
}
@media only screen and (max-width: 575px) {
  .item-value {
    margin-top: 10px;
    text-align: center;
  }
}
.item-value .result {
  color: #2f2f35;
  font-weight: 600;
}

.view-btn-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: -3px -8px;
}
@media only screen and (max-width: 575px) {
  .view-btn-list {
    display: none;
  }
}
.view-btn-list li {
  margin: 3px 8px;
}
.view-btn-list li.active .list-btn {
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  border-color: var(--primary);
  color: var(--secondary);
}

.list-btn {
  width: 40px;
  height: 40px;
  border: 1px solid #e1e7ec;
  background-color: var(--secondary);
  font-size: 24px;
  line-height: 40px;
  text-align: center;
  border-radius: 3px;
}

.search-from {
  position: relative;
}
.search-from input {
  padding: 10px 15px;
  border: 1px solid #e1e7ec;
  min-width: 300px;
  font-size: 13px;
  font-weight: 500;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  color: #606975;
  height: 40px;
}
/* @media only screen and (max-width: 525px) {
  .search-from button {
    min-width: 400px;
    max-height:30%;
  }
} */

@media only screen and (max-width: 1199px) {
  .search-from input {
    min-width: 100%;
  }

  
}
.search-from button, .search-from input[type="button"], .search-from input[type="reset"], .search-from input[type="submit"] {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 18px;
  color: var(--secondary);
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  border-radius: 0 3px 3px 0;
  width: 40px;
  height: 100%;
}

.item-card-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-bottom: -20px;
  /* border-right: 1px solid #e1e7ec; */
  padding-right: 30px;
}
@media only screen and (max-width: 991px) {
  .item-card-wrapper {
    border: none;
    padding-right: 0;
  }
}
.item-card-wrapper.list-view .item-card {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
  position: relative;
}
.item-card-wrapper.list-view .item-card .item-card-content-top {
  margin-top: 0;
  align-items: flex-start;
  width: 100%;
  order: 1;
}
@media only screen and (max-width: 575px) {
  .item-card-wrapper.list-view .item-card .item-card-content-top {
    order: -2 !important;
    align-items: flex-end !important;
    margin-top: -35px !important;
  }
}
.item-card-wrapper.list-view .item-card .item-card-content-top .left {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 575px) {
  .item-card-wrapper.list-view .item-card .item-card-content-top .left {
    display: block;
  }
}
.item-card-wrapper.list-view .item-card .item-card-content-top .author-content {
  width: calc(100% - 40px);
  padding-left: 15px;
}
@media only screen and (max-width: 575px) {
  .item-card-wrapper.list-view .item-card .item-card-content-top .author-content {
    width: 100%;
    padding-left: 0;
    padding-top: 10px;
  }
}
.item-card-wrapper.list-view .item-card-thumb {
  position: absolute;
  width: 295px;
  height: 100%;
  /* background-color: #1e2746; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 767px) {
  .item-card-wrapper.list-view .item-card-thumb {
    width: 220px;
  }
}
@media only screen and (max-width: 575px) {
  .item-card-wrapper.list-view .item-card-thumb {
    width: 100%;
    position: static;
    height: auto;
  }
}
/* .item-card-thumb img {
  padding-left: 2px;
  padding-bottom: 2px;
  padding-top: 2px;
  padding-right: 8px;
} */

.item-card-wrapper.list-view .item-card-thumb img {
  height: 100%;
  padding-left: 5px;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-right: 15px;
 

}
@media only screen and (max-width: 575px) {
  .item-card-wrapper.list-view .item-card-thumb img {
    height: auto;
  }
}
.item-card-wrapper.list-view .item-card-content {
  margin-left: 295px;
  padding: 15px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-bottom: 6px;
}
@media only screen and (max-width: 767px) {
  .item-card-wrapper.list-view .item-card-content {
    margin-left: 220px;
  }
}
@media only screen and (max-width: 575px) {
  .item-card-wrapper.list-view .item-card-content {
    margin-left: 0;
    padding-top: 20px;
  }
}
.item-card-footer a {
  margin-left: 6px;
}
.item-card-wrapper.list-view .item-card-footer {
  margin-left: 295px;
  margin-top: 12px;
}
@media only screen and (max-width: 767px) {
  .item-card-wrapper.list-view .item-card-footer {
    margin-left: 220px;
  }
}
@media only screen and (max-width: 575px) {
  .item-card-wrapper.list-view .item-card-footer {
    margin-left: 0;
  }
}
.item-card-wrapper.grid-view .item-card {
  flex: 0 0 calc((100% / 3) - 15px);
  -ms-flex: 0 0 calc((100% / 3) - 15px);
  max-width: calc((100% / 3) - 15px);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  margin-left: 7px;
  margin-right: 7px;
}
@media only screen and (max-width: 1199px) {
  .item-card-wrapper.grid-view .item-card {
    flex: 0 0 calc((100% / 2) - 15px);
    -ms-flex: 0 0 calc((100% / 2) - 15px);
    max-width: calc((100% / 2) - 15px);
  }
}
@media only screen and (max-width: 767px) {
  .item-card-wrapper.grid-view .item-card {
    flex: 0 0 100%;
    -ms-flex: 0 0 100%;
    max-width: 100%;
  }
}
.item-card-wrapper.grid-view .author-content {
  margin-top: 10px;
}
.item-card-wrapper.grid-view .item-card-title a {
  margin-top: 10px;
}
.item-card-wrapper.grid-view .right .item-amount {
  margin-bottom: -7px;
}

.item-card {
  border: 1px solid #e1e7ec;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 20px;
  transition: all 0.5s;
  background-color: white;
}
.item-card:hover {
  transform: translateY(-7px);
}
.item-card .item-card-thumb {
  position: relative;
}
.item-card .item-card-thumb .item-level {
  position: absolute;
  top: 15px;
  left: -28px;
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  padding: 3px 10px;
  min-width: 120px;
  text-align: center;
  color: var(--secondary);
  font-size: 12px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.item-card .item-card-content {
  padding: 0 20px 20px 20px;
}

.item-card-content-top {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: flex-end;
  position: relative;
  margin-top: -23px;
  justify-content: space-between;
}
.item-card-content-top .author-thumb {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
  border: 1px solid var(--secondary);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}
.item-card-content-top .author-thumb img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
}
.item-card-content-top .author-content .name {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
}
.item-card-content-top .author-content .name .level-text {
  font-size: 12px;
  color: var(--primary);
  font-weight: 500;
  margin-left: 4px;
}
.item-card-content-top .author-content .name .level-text::before {
  content: '-';
}
.item-card-content-top .author-content .ratings {
  font-size: 14px;
}
.item-card-content-top .author-content .ratings i {
  font-size: 12px;
  color: #ffbf00;
}
.item-card-content-top .author-content .ratings .rating {
  color: #ffbf00;
  font-weight: 600;
  font-size: 14px;
}
.item-card-content-top .right .selectQty {
  font-size: 14px;
  border: 1px solid #e1e7ec;
  font-weight: 500;
  border-radius: 3px;
  padding: 5px 10px;
  background-color: transparent;
}
.item-card-content-top .right .item-amount {
  font-size: 22px;
  color: var(--primary);
  font-weight: 600;
}

.item-card-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 7px 20px;
  /* border-top: 1px solid #e1e7ec; */
  /* background-color: #f9f9f9; */
  align-items: center;
  justify-content: space-between;
}
@media only screen and (max-width: 575px) {
  .item-card-footer {
    padding: 7px 5px;
  }
}
.item-card-footer .item-love {
  border: 1px solid #e1e7ec;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 13px;
  margin-right: 0 !important;
}
.item-card-footer .item-love i {
  color: #ea5455;
}
.item-card-footer .item-like {
  border: 1px solid #e1e7ec;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 13px;
}
.item-card-footer .item-like i {
  color: var(--primary);
}

.order-btn a {
  padding: 3px 5px;
  font-size: 12px;
}

.buy-btn {
  padding: 2px 5px;
  font-size: 12px;
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
}
.buy-btn:hover {
  color: var(--secondary) !important;
}

.date-btn {
  padding: 2px 5px;
  font-size: 12px;
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
}
.date-btn:hover {
  color: var(--secondary) !important;
}

.bid-btn {
  padding: 3px 5px;
  font-size: 12px;
}

.item-card-title a {
  font-size: 16px;
  margin-top: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: all 0.3s;
}
.item-card-title a:hover {
  color: var(--primary);
}

.item-card-wrapper.list-view .item-card .item-card-content .item-card-content-top {
  margin-top: 0;
  align-items: flex-start;
  width: 100%;
  order: 1;
}
.item-card-wrapper.list-view .item-card-title {
  order: -1;
}
@media only screen and (max-width: 575px) {
  .item-card-wrapper.list-view .item-card-title {
    margin-top: 10px;
  }
}
.item-card-wrapper.list-view .item-card-title a {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 7px;
  margin-top: 0;
}

.item-tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -3px -3px;
  margin-top: 10px;
}
.item-tags a {
  font-size: 11px;
  margin: 3px 3px;
  border: 1px solid #e1e7ec;
  border-radius: 999px;
  font-weight: 500;
  padding: 1px 10px;
  line-height: 1.8;
  transition: all 0.3s;
}
.item-tags a:hover {
  background-color: var(--primary);
  color: var(--secondary);
  border: 1px solid var(--primary);
}

.item-category-area {
  margin-bottom: 60px;
  position: relative;
}

.category-slider {
  overflow: hidden;
}

.category-item-box {
  overflow: hidden;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  position: relative;
  z-index: 2;
}
.category-item-box::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.3;
}

.category-item-content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px;
}
.category-item-content .title {
  margin-bottom: 0;
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 3px;
}

.item-details-section .item-card {
  flex: 0 0 calc((100% / 4) - 15px) !important;
  -ms-flex: 0 0 calc((100% / 4) - 15px) !important;
  max-width: calc((100% / 4) - 15px) !important;
}
@media only screen and (max-width: 1199px) {
  .item-details-section .item-card {
    flex: 0 0 calc((100% / 2) - 15px) !important;
    -ms-flex: 0 0 calc((100% / 2) - 15px) !important;
    max-width: calc((100% / 2) - 15px) !important;
  }
}
@media only screen and (max-width: 767px) {
  .item-details-section .item-card {
    flex: 0 0 100% !important;
    -ms-flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.item-details-area {

  padding-right: 30px;
}
@media only screen and (max-width: 991px) {
  .item-details-area {
    border: none;
    padding: 0;
  }
}
.item-details-slider-area{
  position: relative;
}
.item-details-slider {
  overflow: hidden;
}
.item-details-slider .slider-next {
  right: -20px;
}
.item-details-slider .slider-prev {
  left: -20px;
}

.item-small-slider {
  overflow: hidden;
}
.item-small-slider .item-small-thumb {
  border-radius: 3px;
  overflow: hidden;
}
.item-small-slider .item-small-thumb img {
  width: 100%;
}

.item-details-thumb-area {
  position: relative;
  padding: 30px;
  border: 1px solid #e1e7ec;
  border-radius: 3px;
  background-color: white;
}
@media only screen and (max-width: 991px) {
  .item-details-thumb-area {
    padding: 20px;
  }
}

.item-details-thumb {
  border-radius: 3px;
  overflow: hidden;
}
.item-details-thumb img {
  width: 100%;
}

.item-details-content {
  padding-top: 15px;
}
.item-details-content .title {
  font-weight: 600;
}

.item-details-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}
.item-details-footer .left{
  width: 80%
}
.item-details-footer .item-love {
  border-radius: 3px;
  padding: 4px 15px;
  font-size: 14px;
  background-color: #ea5455;
  color: var(--secondary);
}
.item-details-footer .item-like {
  border-radius: 3px;
  padding: 4px 15px;
  font-size: 14px;
  background-color: var(--primary);
  color: var(--secondary);
}
.item-details-footer .footer-social li a {
  border: 1px solid #e1e7ec;
  color: #606975;
}
.item-details-footer .footer-social li a:hover, .item-details-footer .footer-social li a.active {
  color: var(--secondary);
  border: 1px solid var(--primary);
}

.product-desc-content {
  padding-top: 15px;
}
.product-desc-content .title {
  font-weight: 600;
  font-size: 24px;
}
@media only screen and (max-width: 991px) {
  .product-desc-content .title {
    font-size: 20px;
  }
}
.product-desc-content p {
  font-size: 14px;
  font-weight: 500;
}

.item-details-tag {
  padding-top: 20px;
  margin-top: 30px;
  border-top: 1px solid #e1e7ec;
}
.item-details-tag ul {
  margin: -3px -3px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.item-details-tag ul li {
  margin: 3px 3px;
}
.item-details-tag ul li.caption {
  font-weight: 600;
}
.item-details-tag ul li a {
  padding: 1px 20px;
  border: 1px solid #e1e7ec;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  font-size: 12px;
  font-weight: 500;
}

.product-reviews-content {
  padding-top: 15px;
}
.product-reviews-content .reviews-title {
  font-size: 20px;
  font-weight: 600;
}
.product-reviews-content .comment-container {
  margin-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #e1e7ec;
}
.product-reviews-content .reply-container {
  padding-left: 60px;
}
@media only screen and (max-width: 767px) {
  .product-reviews-content .reply-container {
    padding-left: 30px;
  }
}
.reply-form-area{
  display: none;
}
.product-reviews-content .comment-avatar {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  overflow: hidden;
}
.product-reviews-content .comment-box {
  width: calc(100% - 65px);
  padding-left: 20px;
}
.product-reviews-content .comment-box .ratings-container {
  margin-bottom: 5px;
}
.product-reviews-content .comment-box .ratings-container i {
  color: var(--primary);
}
.product-reviews-content .comment-info {
  font-size: 14px;
  font-weight: 500;
}
.product-reviews-content .avatar-name {
  display: inline;
  font-weight: 600;
  font-size: 16px;
}
.product-reviews-content .comment-text {
  font-size: 14px;
  font-weight: 500;
}

.item-review-widget-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .item-review-widget-wrapper {
    display: block;
  }
}
.item-review-widget-wrapper .left {
  width: 40%;
  padding: 15px 30px 20px 30px;
  text-align: center;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
}
@media only screen and (max-width: 767px) {
  .item-review-widget-wrapper .left {
    width: 100%;
  }
}
.item-review-widget-wrapper .left .sub-title {
  font-weight: 500;
}
.item-review-widget-wrapper .right {
  width: 55%;
}
@media only screen and (max-width: 767px) {
  .item-review-widget-wrapper .right {
    width: 100%;
    padding-top: 15px;
  }
}
.item-review-widget-wrapper .right ul li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5px;
  font-size: 14px;
  font-weight: 500;
}
.item-review-widget-wrapper .right ul li .caption {
  width: calc(100% - 100px);
}

.comment-form .rating {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.comment-form .rating:not(:checked) > input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.comment-form .rating:not(:checked) > label {
  width: 1em;
  padding: 0 .1em;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  font-size: 200%;
  line-height: 1.2;
  color: #ddd;
}
.comment-form .rating:not(:checked) > label:before {
  content: '★';
  border: none;
}
.comment-form .rating > input[type="radio"]:checked + label:before {
  border-color: transparent;
  background-color: transparent;
}
.comment-form .rating > input:checked ~ label {
  color: #f70;
}
.comment-form .rating:not(:checked) > label:hover,
.comment-form .rating:not(:checked) > label:hover ~ label {
  color: gold;
}
.comment-form .rating > input:checked + label:hover,
.comment-form .rating > input:checked + label:hover ~ label,
.comment-form .rating > input:checked ~ label:hover,
.comment-form .rating > input:checked ~ label:hover ~ label,
.comment-form .rating > label:hover ~ input:checked ~ label {
  color: #ea0;
}
.comment-form .rating > label:active {
  position: relative;
  top: 2px;
  left: 2px;
}

.product-comment-content {
  padding-top: 15px;
}

.reply-btn {
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  color: var(--secondary);
  font-size: 13px;
  padding: 4px 10px;
  border-radius: 3px;
  margin-bottom: 10px;
}

.service-card {
  border: 1px solid #e1e7ec;
  border-radius: 3px;
}
.service-card .service-card-header {
  border-bottom: 1px solid #e1e7ec;
  padding: 8px;
}
.service-card .service-card-header .title {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px;
}
.service-card .right .value {
  font-size: 14px;
  font-weight: 500;
}

.service-card-form .form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 12px 15px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e1e7ec;
}
.service-card-form .custom-check-group {
  margin-bottom: 0;
}
.service-card-form .custom-check-group label {
  margin-bottom: 0;
}

.service-card-btn {
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.service-card-btn a {
  padding: 7px 12px;
  font-size: 14px;
}

.item-overview-section .item-details-area {
  
  padding-right: 50px;
 
  padding-left: 30px;
}
@media only screen and (max-width: 991px) {
  .item-overview-section .item-details-area {
    border: none;
    padding: 0;
  }
}
.item-overview-section .profile-widget-author {
  display: block !important;
  text-align: center;
  margin-left: 0;
  margin-top: -40px !important;
  position: relative;
}
.item-overview-section .profile-widget-author .content {
  width: 100% !important;
  padding-left: 0 !important;
  padding-top: 10px;
}
.item-overview-section .profile-widget-author .content .ratings span {
  font-size: 12px;
  font-weight: 500;
}
.item-overview-section .profile-widget-thumb {
  position: relative;
}
.item-overview-section .profile-widget-thumb .badge-offline {
  position: absolute;
  left: 20px;
  top: 20px;
  color: var(--secondary);
  font-size: 11px;
  padding: 4px 8px;
  border-radius: 999px;
  line-height: 1.2;
}
.item-overview-section .profile-widget-thumb .badge-follow {
  position: absolute;
  right: 20px;
  top: 20px;
  color: var(--secondary);
  font-size: 11px;
  padding: 4px 8px;
  border-radius: 999px;
  line-height: 1.2;
}

.widget p {
  font-size: 14px;
}

.follow-widget-area {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}

.follow-widget-author {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.follow-widget-author .thumb {
  width: 45px;
  height: 45px;
  overflow: hidden;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.follow-widget-author .thumb img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
}
.follow-widget-author .content {
  width: calc(100% - 45px);
  padding-left: 15px;
}
.follow-widget-author .content .name {
  font-weight: 600;
  margin-bottom: 0;
}
.follow-widget-author .content .level {
  font-size: 13px;
  font-weight: 500;
}

.follow-btn a {
  padding: 3px 12px;
  font-size: 12px;
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
}
.follow-btn a:hover {
  color: var(--secondary) !important;
}

.custom-card-wrapper {
  border-right: none;
  padding-right: 0;
  padding-left: 30px;
  border-left: 1px solid #e1e7ec;
}
@media only screen and (max-width: 991px) {
  .custom-card-wrapper {
    border: none;
    padding: 0;
  }
}

/*-------------------------------------------------
    [ ### sidebar block ]
*/

.aside-inner {
  position: sticky;
  top: 10%;
}

.add-item {
  border-radius: 3px;
  overflow: hidden;
}
.add-item a{
  display: block;
}
.add-item.slot-1 a {
  height: 250px;
}
.add-item.slot-1 a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.add-item.slot-2 a {
  height: 600px;
}
.add-item.slot-2 a img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.add-item .add-header {
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  padding: 7px;
}
.add-item .add-header .title {
  color: var(--secondary);
  font-size: 14px;
  margin-bottom: 0;
}

.add-thumb {
  border-radius: 3px;
  overflow: hidden;
}
.add-thumb img {
  border-radius: 3px;
}

.widget-range-area {
  margin-top: 25px;
}

.ui-widget.ui-widget-content {
  position: relative;
  height: 3px;
  border: none;
  margin-right: 20px;
  margin-bottom: 18px;
}

.ui-widget.ui-widget-content::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  height: 3px;
  background: rgba(44, 221, 155, 0.2);
  width: calc(100% + 20px);
}

.ui-slider-range {
  height: 3px;
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  position: relative;
  z-index: 1;
}

.ui-state-default {
  width: 20px;
  height: 20px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  display: block;
  border: none;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  outline: none;
  cursor: pointer;
  top: -9px;
  position: absolute;
  z-index: 1;
}
.ui-state-default::after {
  position: absolute;
  content: "";
  width: 14px;
  height: 14px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: var(--secondary);
  top: 3px;
  left: 3px;
  display: block;
}

.price-range {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.price-range input {
  height: unset;
  width: unset;
  padding: 7px;
  background: transparent;
  border: none;
  text-align: right;
  font-weight: 500;
  padding-right: 0;
}
@media only screen and (max-width: 1199px) {
  .price-range input {
    width: 100px;
  }
}

.filter-btn a {
  padding: 2px 13px;
  font-size: 12px;
  border: 1px solid var(--primary) !important;
  color: var(--primary) !important;
}
.filter-btn a:hover, .filter-btn a:focus {
  color: var(--secondary) !important;
}

.category-list li {
  padding-bottom: 10px;
  padding-left: 12px;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  transition: all 0.3s;
}
.mycategory-list li {
  padding-bottom: 10px;
  font-weight: 500;
  font-size: 14px;
  left: -20px;
  position: relative;
  transition: all 0.3s;
}
.mycategory-list li:hover {
  color: var(--primary);
  margin-left: 5px;
}
.category-list li:hover {
  color: var(--primary);
  margin-left: 5px;
}
.mycategory-list li:hover::before {
  color: var(--primary);
}
.category-list li:hover::before {
  color: var(--primary);
}
.mycategory-list li::before {
  display: block;
  position: absolute;
  top: 10px;
  left: -15px;
  width: 0;
  height: 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: -webkit-transform .35s;
  transition: transform .35s;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  color: #606975;
  content: '';
  transition: all 0.3s;
}
.category-list li::before {
  display: block;
  position: absolute;
  top: 10px;
  left: 0;
  width: 0;
  height: 0;
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
  transition: -webkit-transform .35s;
  transition: transform .35s;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  color: #606975;
  content: '';
  transition: all 0.3s;
}

.small-item-list {
  margin-top: 20px;
}

.small-single-item {
  padding-bottom: 15px;
  margin-bottom: 15px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-bottom: 1px solid #e1e7ec;
}
.small-single-item .thumb {
  width: 95px;
  background-color: #1e2746;
}
.small-single-item .thumb img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  -webkit-object-fit: cover;
  object-position: center;
  -webkit-object-position: center;
}
.small-single-item .content {
  width: calc(100% - 95px);
  padding-left: 20px;
}
.small-single-item .content .title a {
  font-weight: 600;
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.small-single-item .content .ratings i {
  font-size: 12px;
}
.small-single-item .content .ratings span, .small-single-item .content .ratings p {
  font-size: 14px;
}

.custom-widget {
  border: 1px solid #e1e7ec;
  border-radius: 3px;
  padding: 20px;
  background-color: white;
}

.widget-btn a {
  padding: 7px 15px;
  font-size: 14px;
}

.details-list li {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px dashed #e1e7ec;
  padding-bottom: 7px;
  margin-bottom: 7px;
}

.profile-widget {
  border: 1px solid #e1e7ec;
  border-radius: 3px;
  overflow: hidden;
}
.profile-widget .profile-list-area {
  padding: 20px;
}
.profile-widget .profile-widget-thumb img {
  width: 100%;
}

.profile-widget-header {
  padding-bottom: 30px;
  border-bottom: 1px solid #e1e7ec;
}
.profile-widget-header .profile-widget-author {
  display: flex;
  flex-wrap: wrap;
  margin-top: -25px;
  margin-left: 20px;
  align-items: flex-end;
}
.profile-widget-header .profile-widget-author .thumb {
  width: 90px;
  height: 90px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 5px 10px rgb(0 0 0 / 15%);
}
.profile-widget-header .profile-widget-author .thumb img {
  width: inherit;
  height: inherit;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
}
.profile-widget-header .profile-widget-author .content {
  padding-left: 20px;
  width: calc(100% - 90px);
}
.profile-widget-header .profile-widget-author .content .name {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}
.profile-widget-header .profile-widget-author .content .designation {
  font-size: 13px;
  font-weight: 500;
}

.profile-widget-author-meta {
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: 20px;
  margin-right: 20px;
}
.profile-widget-author-meta .location {
  font-size: 14px;
  display: flex;
  align-items: center;
  font-weight: 500;
}
.profile-widget-author-meta .location i {
  width: 22px;
  height: 22px;
  margin-right: 5px;
  text-align: center;
  line-height: 22px;
  border: 1px solid #e1e7ec;
  font-size: 10px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}
.profile-widget-author-meta .btn-area a {
  font-size: 12px;
  padding: 3px 10px;
}

.total-price-area {
  padding-top: 10px;
}
.total-price-area .title {
  color: #606975;
  font-size: 18px;
  font-weight: 600;
}

.coupon-form {
  position: relative;
}
.coupon-form .coupon-form-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--secondary);
  font-size: 20px;
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  border-radius: 0 3px 3px 0;
}

/*-------------------------------------------------
    [ ### account block ]
*/
.account-section{
  min-height: calc(100vh - 307px);
}
.account-logo-area {
  position: relative;
  margin-bottom: 20px;
}
.account-logo-area .account-logo img {
  height: 30px;
}

.account-header {
  margin-bottom: 30px;
}
.account-header .title {
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  position: relative;
}
.account-header .title::before {
  content: "";
  height: 1px;
  position: absolute;
  top: 10px;
  width: 35%;
  background: -webkit-linear-gradient(right, rgba(255, 255, 255, 0.2) 0%, rgba(96, 105, 117, 0.3) 100%);
  left: 0;
}
.account-header .title::after {
  content: "";
  height: 1px;
  position: absolute;
  top: 10px;
  width: 35%;
  background: -webkit-linear-gradient(right, rgba(96, 105, 117, 0.3) 0%, rgba(255, 255, 255, 0.2) 100%);
  right: 0;
}
@media only screen and (max-width: 991px) {
  .account-header .title {
    font-size: 16px;
  }
}

.account-form-area {
  position: relative;
  padding: 30px;
  border-radius: 3px;
  background: white;
  border: 1px solid #e1e7ec;
}
@media only screen and (max-width: 767px) {
  .account-form-area {
    padding: 20px;
  }
}
.account-form-area::before {
  background: white;
  border: 1px solid #e1e7ec;
  border-radius: 3px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f8f8f8', endColorstr='#f9f9f9',GradientType=0 );
  content: "";
  display: block;
  height: 100%;
  left: -1px;
  position: absolute;
  width: 100%;
  -webkit-transform: rotate(-3deg);
  -moz-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  -o-transform: rotate(-3deg);
  transform: rotate(-3deg);
  top: 0;
  z-index: -2;
}
@media only screen and (max-width: 991px) {
  .account-form-area::before {
    display: none;
  }
}
.account-form-area::after {
  background: var(--secondary);
  border: 1px solid #e1e7ec;
  border-radius: 3px;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f8f8f8', endColorstr='#f9f9f9',GradientType=0 );
  content: "";
  display: block;
  height: 100%;
  left: -1px;
  position: absolute;
  width: 100%;
  -webkit-transform: rotate(2deg);
  -moz-transform: rotate(2deg);
  -ms-transform: rotate(2deg);
  -o-transform: rotate(2deg);
  transform: rotate(2deg);
  top: 0;
  z-index: -1;
}
@media only screen and (max-width: 991px) {
  .account-form-area::after {
    display: none;
  }
}

/*-------------------------------------------------
    [ ### contact block ]
*/
.contact-wrapper {
  border-radius: 3px;
  overflow: hidden;
}

.contact-form-area {
  background-color: #f9f9f9;
  padding: 40px;
}
@media only screen and (max-width: 991px) {
  .contact-form-area {
    padding: 40px 20px;
  }
}
.contact-form-area .title {
  font-size: 26px;
  margin-bottom: 15px;
  font-weight: 600;
}
@media only screen and (max-width: 991px) {
  .contact-form-area .title {
    font-size: 22px;
  }
}
.contact-form-area p {
  font-size: 14px;
}
.contact-form-area .contact-form {
  margin-top: 30px;
}
.contact-form-area textarea {
  border-radius: 3px;
  padding: 10px 20px;
  min-height: 150px;
}

.contact-info-item-area {
  height: 100%;
  position: relative;
  padding: 40px;
  z-index: 2;
  border-radius: 3px 0 0 3px;
  overflow: hidden;
}
@media only screen and (max-width: 991px) {
  .contact-info-item-area {
    padding: 40px 20px;
  }
}
.contact-info-item-area::after {
  background-color: #1e2746;
  opacity: .95;
  content: "";
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: -1;
}
.contact-info-item-area .header-title {
  font-size: 26px;
  margin-bottom: 15px;
  font-weight: 600;
  color: var(--secondary);
}
@media only screen and (max-width: 991px) {
  .contact-info-item-area .header-title {
    font-size: 22px;
  }
}
.contact-info-item-area p {
  font-size: 14px;
  color: var(--secondary);
}

.contact-info-icon i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  background-color: var(--primary);
  box-shadow: 7px 5px 30px 0px rgba(72, 73, 121, 0.15);
  text-align: center;
  display: inline-block;
  border-radius: 3px;
  color: var(--secondary);
  font-size: 16px;
}

.contact-info-content {
  width: calc(100% - 50px);
  padding-left: 15px;
}
.contact-info-content .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--secondary);
}
@media only screen and (max-width: 991px) {
  .contact-info-content .title {
    font-size: 16px;
  }
}
.contact-info-content p {
  font-size: 14px;
}
@media only screen and (max-width: 991px) {
  .contact-info-content p {
    font-size: 12px;
  }
}

.maps {
  height: 400px;
}

/*-------------------------------------------------
    [ ### dashboard block ]
*/
.section-wrapper {
  padding: 0 180px;
}
@media only screen and (max-width: 1800px) {
  .section-wrapper {
    padding: 0 140px;
  }
}
@media only screen and (max-width: 1600px) {
  .section-wrapper {
    padding: 0 50px;
  }
}
@media only screen and (max-width: 1450px) {
  .section-wrapper {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 1199px) {
  .section-wrapper {
    padding: 0;
  }
}
.sections-wrapper{
  display: block !important;
}
.dashboard-sidebar {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  height: 100%;
}
.dashboard-sidebar.open {
  left: 0;
}
@media only screen and (max-width: 1199px) {
  .dashboard-sidebar {
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 999999;
    overflow: auto;
    height: 100vh;
  }
  .dashboard-sidebar .dashboard-sidebar-inner {
    border-radius: 0;
  }
}

.dashboard-sidebar-wrapper-inner{
  position: sticky;
  top: 10px;
}

.dashboard-sidebar-open {
  padding: 5px 10px;
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  display: inline-block;
  color: var(--secondary);
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 30px;
  display: none;
}
@media only screen and (max-width: 1199px) {
  .dashboard-sidebar-open {
    display: inline-block;
  }
}

.dashboard-sidebar-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 10px;
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  color: var(--secondary);
  display: none;
}
@media only screen and (max-width: 1199px) {
  .dashboard-sidebar-close {
    display: block;
  }
}

.dashboard-sidebar-inner {
  background-color: #1e2746;
  padding: 20px 20px;
  border-radius: 5px;
  height: 100%;
}

.menu-header-title {
  color: var(--secondary);
  text-transform: uppercase;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
  margin-bottom: 15px;
}

.sidebar-main-menu {
  margin-bottom: 20px;
}
.sidebar-main-menu:last-child {
  margin-bottom: 0;
}
.sidebar-main-menu li {
  position: relative;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 5px;
}
.sidebar-main-menu li.open a {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--primary);
  border-left: 2px solid var(--primary);
}
.sidebar-main-menu li a {
  position: relative;
  display: block;
  padding: 6px 10px;
  color: var(--secondary);
  z-index: 2;
  transition: all 0.3s;
  border-radius: 5px;
}
.sidebar-main-menu li a:hover {
  color: var(--primary);
}
.sidebar-main-menu li a i {
  margin-right: 5px;
}
.sidebar-main-menu li.has-sub.active {
  background-color: rgba(255, 255, 255, 0.1);
  color: var(--primary);
  border-left: 2px solid var(--primary);
  border-radius: 5px;
}
.sidebar-main-menu li.has-sub a::before {
  position: absolute;
  content: '\f105';
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: var(--secondary);
  font-size: 11px;
  right: 20px;
  top: 10px;
}
.sidebar-main-menu li ul {
  position: relative;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  display: none;
  z-index: 1;
}
.sidebar-main-menu li ul li a {
  padding-left: 40px;
}
.sidebar-main-menu li ul li a::before {
  display: none;
}

.header-right {
  padding-left: 20px;
  margin-left: 20px;
  border-left: 1px solid #e1e7ec;
}
@media only screen and (max-width: 991px) {
  .header-right {
    margin-bottom: 20px;
  }
}
.header-right button, .header-right input[type="button"], .header-right input[type="reset"], .header-right input[type="submit"] {
  background-color: transparent;
  padding: 0;
}

.header-user-thumb {
  width: 35px;
}
.header-user-thumb img {
  width: 35px;
  height: 35px;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.header-user-content {
  padding-left: 10px;
}
.header-user-content span {
  color: #606975;
  font-size: 14px;
  font-weight: 500;
}

.header-user-icon {
  padding-left: 7px;
  color: #606975;
}

.dropdown-menu.dropdown-menu--sm {
  min-width: 12rem;
}

.dropdown-menu {
  pointer-events: none;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  display: block;
  opacity: 0;
  visibility: hidden;
  border: none;
  background-color: var(--secondary);
  box-shadow: 7px 5px 30px 0px rgba(72, 73, 121, 0.15);
}

.dropdown-menu.show {
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  opacity: 1;
  visibility: visible;
}

.dropdown-menu__item {
  border-bottom: 1px solid #e1e7ec;
}
.dropdown-menu__item:last-child {
  border: none;
}
.dropdown-menu__item .dropdown-menu__icon {
  color: #606975;
}
.dropdown-menu__item .dropdown-menu__caption {
  padding-left: 10px;
  font-size: 14px;
  color: #606975;
  font-weight: 500;
}

.profile-settings-wrapper .preview-thumb.profile-wallpaper {
  display: block;
  position: relative;
}
.profile-settings-wrapper .preview-thumb.profile-wallpaper .avatar-preview {
  width: 100%;
  height: 250px;
  border-radius: 0;
}
.profile-settings-wrapper .preview-thumb.profile-wallpaper .avatar-preview .profilePicPreview {
  width: 100%;
  height: 250px;
  border-radius: 3px 3px 0 0;
  border: none;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--secondary);
}
.profile-settings-wrapper .preview-thumb.profile-wallpaper .avatar-edit {
  position: absolute;
  bottom: auto;
  top: 20px;
  right: 20px;
}
.profile-settings-wrapper .preview-thumb.profile-wallpaper .avatar-edit .profilePicUpload {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.profile-settings-wrapper .preview-thumb.profile-wallpaper .avatar-edit label {
  width: auto;
  height: auto;
  padding: 2px 10px;
  background-color: var(--secondary);
  border-radius: 99px;
  color: #2f2f35;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  cursor: pointer;
}

.profile-thumb-content {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  padding: 0 3rem;
}
@media only screen and (max-width: 767px) {
  .profile-thumb-content {
    display: block;
    text-align: center;
    padding: 0;
  }
}
.profile-thumb-content .profile-thumb {
  position: relative;
  display: inline-block;
  margin-right: 20px;
  margin-top: -100px;
}
.profile-thumb-content .profile-thumb .avatar-preview {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.profile-thumb-content .profile-thumb .avatar-preview .profilePicPreview {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  border: 2px solid #e1e7ec;
  background-color: var(--secondary);
  background-size: cover;
  background-repeat: no-repeat;
}
.profile-thumb-content .profile-thumb .avatar-edit {
  position: absolute;
  bottom: 0;
  right: 0;
}
.profile-thumb-content .profile-thumb .avatar-edit .profilePicUpload {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
}
.profile-thumb-content .profile-thumb .avatar-edit label {
  width: 35px;
  height: 35px;
  background-color: var(--secondary);
  border-radius: 50%;
  color: #2f2f35;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.1);
}
.profile-thumb-content .profile-content {
  transform: translateY(-32px);
}
@media only screen and (max-width: 767px) {
  .profile-thumb-content .profile-content {
    transform: none;
  }
}
.profile-thumb-content .profile-content .username {
  font-size: 22px;
  color: var(--secondary);
}
@media only screen and (max-width: 767px) {
  .profile-thumb-content .profile-content .username {
    color: #2f2f35;
  }
}
.profile-thumb-content .profile-content .user-info-list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: -5px -10px;
}
@media only screen and (max-width: 767px) {
  .profile-thumb-content .profile-content .user-info-list {
    justify-content: center;
  }
}
.profile-thumb-content .profile-content .user-info-list li {
  margin: 5px 10px;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-weight: 500;
}
.profile-thumb-content .profile-content .user-info-list li i {
  font-size: 18px;
  margin-right: 5px;
  color: var(--primary);
}

.chat-area li {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px 30px;
  align-items: center;
  border-bottom: 1px solid #e1e7ec;
}
@media only screen and (max-width: 991px) {
  .chat-area li {
    padding: 20px 20px;
  }
}
.chat-area li:last-child {
  border: none;
}
.chat-area li .chat-author {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.chat-area li .chat-author .thumb {
  width: 40px;
  height: 40px;
  background: white;
  overflow: hidden;
  border-radius: 50%;
}
.chat-area li .chat-author .thumb img {
  width: 100%;
}
.chat-area li .chat-author .content {
  width: calc(100% - 40px);
  padding-left: 15px;
}
.chat-area li .chat-author .content .name {
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 0;
}
.chat-area li .chat-author .content .info {
  font-size: 13px;
  font-weight: 500;
}
.chat-area li .date-area span {
  font-size: 14px;
  font-weight: 500;
}

.ps-container {
  position: relative;
  -ms-touch-action: auto;
  touch-action: auto;
  overflow-y: auto;
  max-height: 565px;
}
@media only screen and (max-width: 991px) {
  .ps-container {
    max-height: 435px;
  }
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color .2s linear;
  transition: background-color .2s linear;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 0;
}
.media.media-chat-reverse {
  padding-right: 12px;
  padding-left: 64px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}
@media only screen and (max-width: 991px) {
  .media.media-chat-reverse {
    padding-left: 0;
  }
}
.media .avatar {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  background-color: var(--secondary);
  text-transform: uppercase;
  flex-shrink: 0;
}
.media .media-body p {
  position: relative;
  padding: 10px 20px;
  margin: 4px 0;
  background-color: #1e2746;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 500;
  color: var(--secondary);
}

.media > * {
  margin: 0 8px;
}

.publisher {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 12px 20px;
  background-color: #f9f9f9;
  border-top: 1px solid #e1e7ec;
}
@media only screen and (max-width: 575px) {
  .publisher {
    display: block;
  }
}
.publisher .chatbox-message-part {
  width: calc(100% - 158px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
@media only screen and (max-width: 575px) {
  .publisher .chatbox-message-part {
    width: 100%;
  }
}
.publisher .chatbox-message-part .avatar {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  background-color: var(--secondary);
  text-transform: uppercase;
  flex-shrink: 0;
}

.publisher > * {
  margin: 0 8px;
}
.publisher > *:first-child {
  margin-left: 0;
}

.publisher-input {
  width: calc(100% - 50px);
  font-weight: 500;
  font-size: 14px;
  border: none;
  outline: none !important;
  background-color: transparent;
}
@media only screen and (max-width: 575px) {
  .publisher-input {
    border-bottom: 1px solid #e1e7ec;
  }
}

.chatbox-send-part {
  width: 158px;
}
@media only screen and (max-width: 575px) {
  .chatbox-send-part {
    width: 100%;
    margin-top: 20px;
    justify-content: flex-end;
  }
}

.publisher-btn {
  width: 35px;
  height: 35px;
  background-color: #1e2746;
  color: var(--secondary);
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-group {
  position: relative;
  overflow: hidden;
}
.file-group label {
  margin-bottom: 0;
  color: var(--secondary);
}

.file-group input[type="file"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 20px;
}

.ticket-input-group .input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding: .375rem .75rem;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #606975;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #e1e7ec;
  border-radius: .25rem 0 0 .25rem;
  height: 35px;
}
.ticket-input-group .custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin: 0;
  opacity: 0;
}
.ticket-input-group .custom-file {
  display: --ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0;
}
.ticket-input-group .custom-file::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + .75rem);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #606975;
  font-weight: 500;
  font-size: 14px;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0;
}
.ticket-input-group .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #606975;
  background-color: var(--secondary);
  border: 1px solid #e1e7ec;
  border-radius: .25rem;
  font-weight: 500;
  font-size: 14px;
}

.image-upload .thumb .profilePicPreview {
  width: 100%;
  height: 310px;
  display: block;
  border: 3px solid #f1f1f1;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-size: cover !important;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.image-upload .thumb .profilePicPreview .remove-image {
  position: absolute;
  top: -9px;
  right: -9px;
  text-align: center;
  width: 55px;
  height: 55px;
  font-size: 24px;
  border-radius: 50%;
  background-color: #df1c1c;
  color: var(--secondary);
  display: none;
}

.image-upload .thumb .profilePicUpload {
  font-size: 0;
  display: none;
}

.image-upload .thumb .avatar-edit label {
  text-align: center;
  line-height: 35px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 2px 25px;
  width: 100%;
  border-radius: 5px;
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  transition: all 0.3s;
  margin-top: 20px;
}

.custom-file-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.custom-file-wrapper .custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin-bottom: 0;
  width: calc(100% - 40px);
  margin: 5px 0;
}
.custom-file-wrapper .custom-file input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + .75rem + 2px);
  margin: 0;
  opacity: 0;
}
.custom-file-wrapper .custom-file .custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-weight: 500;
  line-height: 1.5;
  color: #606975;
  font-weight: 500;
  background-color: var(--secondary);
  border: 1px solid #e1e7ec;
  border-radius: .25rem;
}
.custom-file-wrapper .custom-file .custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: calc(1.5em + .75rem);
  padding: .375rem .75rem;
  line-height: 1.5;
  color: #495057;
  content: "Browse";
  background-color: #e9ecef;
  border-left: inherit;
  border-radius: 0 .25rem .25rem 0;
}

.btn-icon {
  line-height: 1em;
  padding: 10px 20px;
  background: transparent;
}
.btn-icon i {
  margin-right: 3px;
}

.panel-card-header {
  font-size: 14px;
  padding: 7px 20px;
  font-weight: 700;
}
.panel-card-header i {
  margin-right: 5px;
}

.panel-card-body {
  padding: 25px;
}
@media only screen and (max-width: 991px) {
  .panel-card-body {
    padding: 10px;
  }
}

.panel-card-widget-area {
  padding: 15px;
}
.panel-card-widget-area .panel-card-widget-list a {
  font-size: 14px;
  color: #2f2f35;
  font-weight: 600;
  border: 1px solid #e1e7ec;
  padding: 1px 7px;
  margin: 0;
  border-radius: 3px;
  transition: all 0.3s;
}
.panel-card-widget-area .panel-card-widget-list a:hover {
  background-color: #f9f9f9;
}

.panel-card-footer-area {
  padding: 15px;
}
.panel-card-footer-area span, .panel-card-footer-area a {
  font-size: 14px;
  color: #2f2f35;
  font-weight: 600;
}
.panel-card-footer-area .panel-widget-page-area {
  margin: -5px;
}
.panel-card-footer-area .panel-widget-page-area a {
  border: 1px solid #e1e7ec;
  padding: 1px 7px;
  margin: 5px;
  transition: all 0.3s;
}
.panel-card-footer-area .panel-widget-page-area a:hover {
  background-color: #f9f9f9;
}

.dashboard-item {
  padding: 20px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  z-index: 2;
  transition: all 0.5s;
}
.dashboard-item:hover {
  transform: translateY(-7px);
}
.dashboard-item::before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  width: 30%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  z-index: -1;
}
.dashboard-item a {
  position: absolute;
  top: 20px;
  right: 20px;
  padding: 1px 8px;
  background-color: var(--secondary);
  font-size: 12px;
  font-weight: 500;
  border-radius: 3px;
  transition: all 0.3s;
}

.dashboard-icon {
  width: 75px;
  height: 75px;
  background-color: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 42px;
  color: var(--secondary);
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.08);
}

.dashboard-content {
  padding-left: 25px;
  width: calc(100% - 75px);
}
.dashboard-content .num {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-right: 50px;
  word-break: break-all;
}
@media only screen and (max-width: 991px) {
  .dashboard-content .num {
    font-size: 20px;
  }
}
.dashboard-content .title {
  font-size: 14px;
  margin-bottom: 0;
}
@media only screen and (max-width: 991px) {
  .dashboard-content .title {
    font-size: 14px;
  }
}

.deposit-item {
  box-shadow: 7px 5px 30px 0px rgba(72, 73, 121, 0.15);
  border-radius: 3px;
  overflow: hidden;
}

.deposit-item-header {
  padding: 5px 10px;
  border-radius: 3px 3px 0 0;
  font-size: 14px;
  font-weight: 500;
}

.deposit-preview-area .deposit-thumb-area {
  border: 1px dotted #1e2746;
  padding: 20px;
}
.deposit-preview-area .deposit-thumb {
  width: 230px;
}
@media only screen and (max-width: 767px) {
  .deposit-preview-area .deposit-thumb {
    width: 100%;
  }
}
.deposit-preview-area .deposit-list li {
  border: 1px dotted #1e2746;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
}

.deposit-item-body {
  padding: 30px;
}
@media only screen and (max-width: 991px) {
  .deposit-item-body {
    padding: 15px;
  }
}
.deposit-item-body .deposit-thumb {
  margin: 0 auto;
}
.deposit-item-body .deposit-thumb img {
  width: 100%;
}

.deposit-item-footer button, .deposit-item-footer input[type="button"], .deposit-item-footer input[type="reset"], .deposit-item-footer input[type="submit"], .deposit-item-footer a {
  font-size: 14px;
}

.withdraw-log-area {
  padding: 20px;
}

.withdraw-form-area {
  padding: 20px;
}

.withdraw-log-list li {
  font-weight: 500;
  padding-bottom: 8px;
  font-size: 14px;
}
.withdraw-log-list li span {
  float: right;
}

/*--------------------------------------------------------------
    [ ## Blog ]
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Blog-block
--------------------------------------------------------------*/
.blog-item {
  border: 1px solid #e1e7ec;
  border-radius: 3px;
  padding: 10px;
  transition: all 0.3s;
}
.blog-item:hover {
  transform: translateY(-7px);
}

.blog-thumb {
  position: relative;
  border-radius: 3px;
  overflow: hidden;
}
.blog-thumb img {
  display: block;
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .blog-thumb img {
    height: auto;
  }
}
.blog-thumb::after {
  position: absolute;
  content: "";
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  margin-left: -8px;
  width: 0;
  height: 0;
  border-left: 14px solid transparent;
  border-right: 14px solid transparent;
  border-bottom: 14px solid var(--secondary);
}
.blog-thumb .blog-date {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 60px;
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  padding: 5px 0;
  border-radius: 3px;
}
.blog-thumb .blog-date .title {
  font-size: 18px;
  color: var(--secondary);
  font-weight: 500;
  margin-bottom: 0;
}
.blog-thumb .blog-date .sub-title {
  font-size: 13px;
  width: 100%;
  display: block;
  color: var(--secondary);
  font-weight: 500;
}

.blog-content-inner {
  background-color: var(--secondary);
  padding: 20px 10px 10px 10px;
}
.blog-content-inner .title {
  font-size: 18px;
  font-weight: 600;
}
.blog-content-inner .title a {
  transition: all 0.3s;
}
.blog-content-inner .title a:hover {
  color: var(--primary);
}
.blog-content-inner p {
  font-size: 14px;
}

.blog-btn a {
  font-size: 13px;
  padding: 5px 15px;
}

.blog-details-section .blog-thumb img {
  height: auto;
}
.blog-details-section .blog-content-inner .title {
  font-size: 24px;
}
@media only screen and (max-width: 991px) {
  .blog-details-section .blog-content-inner .title {
    font-size: 20px;
  }
}
.blog-details-section .blog-content-inner p {
  font-size: 15px;
}
.blog-details-section .blog-item-area {
  border-right: 1px solid #e1e7ec;
  padding-right: 30px;
}

.blog-social-area {
  border: 1px solid #e1e7ec;
  margin-top: 30px;
  padding: 20px;
  border-radius: 3px;
}
.blog-social-area .title {
  margin-bottom: 0;
  font-size: 18px;
}
@media only screen and (max-width: 991px) {
  .blog-social-area .title {
    margin-bottom: 10px;
  }
}
.blog-social-area .blog-social li {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 1px solid #e1e7ec;
  text-align: center;
  font-size: 14px;
  border-radius: 3px;
  transition: all 0.3s;
}
.blog-social-area .blog-social li:hover {
 background: var(--primary);
  background: -webkit-linear-gradient(legacy-direction(to right), var(--primary) 0%, var(--primary) 100%);
  background: -webkit-gradient(linear, left top, right top, from(var(--primary)), to(var(--primary)));
  background: -webkit-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: -o-linear-gradient(left, var(--primary) 0%, var(--primary) 100%);
  background: linear-gradient(to right, var(--primary) 0%, var(--primary) 100%);
  color: var(--secondary);
  border: 1px solid var(--primary);
}
.blog-social-area .blog-social li + li {
  margin-left: 5px;
}

.sidebar {
  position: sticky;
  top: 10%;
}

.optional-thumb{
  width: 200px;
}

.item-details-section .item-card-wrapper--style .item-card {
  flex: 0 0 100% !important;
  -ms-flex: 0 0 100% !important;
  max-width: 100% !important;
}
.item-details-section .item-card-wrapper--style .item-card .item-card-content {
  margin-left: 0;
  padding-bottom: 15px;
}
.item-details-section .item-card-wrapper--style .item-card .item-card-content .item-card-title{
  width: 80%
}
.item-details-section .item-card-wrapper--style .item-card .item-card-content .item-card-content-top {
  display: block;
}
@media only screen and (max-width: 575px) {
  .item-details-section .item-card-wrapper--style .item-card .item-card-content .item-card-content-top {
    margin-top: 0 !important;
  }
}
.item-details-section .item-card-wrapper--style .item-card .item-card-content p {
  font-size: 14px;
}
@media only screen and (max-width: 991px) {
  .item-details-section .item-card-wrapper--style .item-card .item-card-content p {
    margin-top: 5px;
  }
}
.like-dislike{
    margin-bottom: 20px;
}
.like-dislike input {
    width: 0;
    height: 0;
    opacity: 0;
    visibility: hidden;
}
.like-dislike label {
    font-size: 26px;
}
.like-dislike input:checked ~ label[for="review-like"] {
    color: #28C76F;
}
.like-dislike input:checked ~ label[for="review-dislike"] {
    color: #EA5455;
}
.empty-message-box {
    padding: 100px 50px;
    border: 1px solid #e5e5e5;
    border-radius: 3px;
    text-align: center;
    width: 100%;
} 
.empty-message-box .icon {
    font-size: 120px;
    line-height: 1;
    animation: shake 1s 1 linear;
} 
.empty-message-box .caption {
    font-size: 24px;
    font-weight: 500;
    margin-top: 10px;
} 
@media(max-width: 767px) {
    .empty-message-box .icon {
    font-size: 90px
    } 
    .empty-message-box .caption {
        font-size: 18px;
    }
}
.modal{
  z-index: 9999;
}
.modal .form-control{
  height: auto;
}
.deposit-item-footer .btn--base{
  background: transparent;
}
.swiper-button-disabled{
  display: none;
}


/* SIDE BAR  */
.Side-Bar{
  padding: 20px;
  background-color: white;
}
.product-tab{
  background-color: white;
  padding: 15px;
}
.my-side-bar{
  background-color: white;
}